import { Outlet } from "react-router-dom";
import CPage from "../../components/CPage";

import { UserPage } from "../../lib/api/types";
import UsersTable from "./components/UsersTable";
import { useUsers } from "./api/useUsers";

export default function UsersPage() {
  const initialUserPage = useUsers(1, 30, "") as UserPage;

  return (
    <CPage
      title="Manage your users"
      primaryAction={{
        title: "Add a user",
        url: "./create",
      }}
      secondaryActions={[
        {
          title: "Generate invite code",
          url: "./invite",
        },
        {
          title: "Link User to Cordial Account",
          url: "./link-user",
        },
      ]}
    >
      {initialUserPage && initialUserPage.users ? (
        <UsersTable initialUserPage={initialUserPage} />
      ) : (
        <p>No users available</p>
      )}
      <Outlet />
    </CPage>
  );
}
