import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { useAccountPage } from "../api/getAccounts";
import { ParseName } from "../../../lib/api/name";
import { formatDate } from "../../../utils/formater";
import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";

const AccountTable: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPageToken,
    setPrevPageTokens,
    prevPageTokens,
    pageToken,
  } = usePagination();

  const { data, isLoading, error } = useAccountPage({ pageSize, pageToken });

  useEffect(() => {
    if (data && data.total_size) {
      setTotalItems(data.total_size);
    }
  }, [data, setTotalItems]);

  useEffect(() => {
    if (data?.next_page_token) {
      if (page > prevPageTokens.length + 1) {
        setPrevPageTokens([...prevPageTokens, data.next_page_token]);
        setPageToken(data.next_page_token);
      }
    }
  }, [page, data, prevPageTokens, setPrevPageTokens, setPageToken]);

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "update_time", header: "Updated" },
    { key: "name", header: "Name" },
    { key: "notes", header: "Description" },
    { key: "variant", header: "Variant" },
    { key: "version", header: "Version" },
  ];

  const extractDescription = (notes: any): string => {
    if (!notes) return "";

    if (typeof notes === "object" && notes.description) {
      return notes.description;
    }

    if (typeof notes === "string") {
      try {
        const parsedData = JSON.parse(notes);
        return parsedData.description || "";
      } catch (error) {
        console.error("Invalid JSON string provided:", error);
        return notes;
      }
    }

    return "";
  };

  const filteredRows = (data?.accounts || [])
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      name: ParseName(item.name!, "Account").resourceId,
      create_time: formatDate(item.create_time ?? "") || "",
      update_time: formatDate(item.update_time ?? "") || "",
      notes: extractDescription(item.notes),
    }))
    .filter((row) => {
      return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
      );
    });

  const renderCellContent = (cell: any): React.ReactElement | string => {
    if (typeof cell.value === "object" && cell.value !== null) {
      return JSON.stringify(cell.value);
    }
    return cell.value ?? "";
  };

  const handleRowClick = (rowId: string) => {
    navigate(`/accounts/${rowId}`);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    // @ts-expect-error error
                    key={row.id}
                    {...getRowProps({ row })}
                    onClick={() => handleRowClick(row.cells[2].value)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AccountTable;
