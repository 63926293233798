import React, { ChangeEvent, useState, useEffect } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  Modal,
} from "@carbon/react";
import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import { TransferRule, TransferRulePage } from "@/lib/api/types";
import { formatDate } from "../../../utils/formater";
import { ParseName } from "../../../lib/api/name";
import { useDeleteRule } from "../api/deleteTransferRule";
import { useNavigate } from "react-router-dom";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useAsset } from "../../../features/assetManagement/api/getAsset";

interface PolicyTableProps {
  transferRulePage: TransferRulePage | null;
}

const PolicyTable: React.FC<PolicyTableProps> = ({ transferRulePage }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<TransferRule | null>(null);
  const deleteRule = useDeleteRule();
  const navigate = useNavigate();
  const maxAssetInRender = 3;

  const { page, pageSize, pageSizes, totalItems, setTotalItems, handlePageChange, setPage } =
    usePagination();

  const transferRules: TransferRule[] = transferRulePage?.["transfer-rules"] ?? [];

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "asset", header: "Asset" },
    { key: "name", header: "Name" },
    { key: "notes", header: "Description" },
    { key: "variant", header: "Variant" },
    { key: "from", header: "From" },
    { key: "to", header: "To" },
    { key: "actions", header: "Actions" },
  ];

  const extractDescription = (notes: any): string => {
    if (!notes) return "";
    if (typeof notes === "object" && notes.description) {
      return notes.description;
    }
    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        return data.description || "";
      } catch (error) {
        console.error("Invalid JSON string provided:", error);
        return notes;
      }
    }
    return "";
  };

  const filteredRows = transferRules
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      name: ParseName(item.name ? [item.name] : [], "TransferRule").resourceId || "NA",
      create_time: formatDate(item.create_time ?? "") || "",
      notes: extractDescription(item.notes),
      actions: item,
      approvals: item.approvals,
      searchableText: `
        ${formatDate(item.create_time ?? "")} 
        ${item.asset} 
        ${ParseName(item.name ? [item.name] : [], "TransferRule").resourceId || "NA"} 
        ${item.from} 
        ${item.to}
      `.toLowerCase(),
    }))
    .filter((row) => {
      return row.searchableText.includes(searchQuery.toLowerCase());
    });

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  if (!transferRulePage) {
    return <div>Loading data, please wait...</div>;
  }

  const getElementType = (input: any): string => {
    if (typeof input === "string") {
      const parts = input.split("/");
      return input.endsWith("/defaults") ? parts[0] + "-defaults" : parts[0];
    }
    if (Array.isArray(input)) {
      const parts = input[0].split("/");
      return parts[0] + "-isArray";
    }
    throw new Error("Invalid input type");
  };

  const processAssetString = (input: any): string | string[] => {
    if (Array.isArray(input)) {
      const displayedAssets = input.slice(0, maxAssetInRender).map(processAssetString).join(", ");

      const remainingCount = input.length - maxAssetInRender;

      return remainingCount > 1 ? `${displayedAssets} [ +${remainingCount} more]` : displayedAssets;
    }

    if (typeof input !== "string") {
      console.error("Expected a string or an array but got:", input);
      return input;
    }

    const match = input.match(/assets\/([^,]+)/g);

    const truncatedAssets = match
      ? match.map((asset) => truncateMiddle(asset.split("/")[1], 20))
      : [truncateMiddle(input, 20)];

    return truncatedAssets.join(", ");
  };

  const processChainAdressString = (input: any) => {
    const formatAddress = (address: string) => {
      if (address.length <= 15) {
        return address;
      }
      return `${address.slice(0, 6)}...${address.slice(-6)}`;
    };

    if (Array.isArray(input)) {
      const formattedAddresses = input.map((address) => {
        const parts = address.split("/");
        const extractedAddress = parts[parts.length - 1];
        return formatAddress(extractedAddress);
      });

      return formattedAddresses.join(", ");
    }

    console.error("Expected an array but got:", input);
    return input;
  };

  const removeEndingAndUpperCase = (str: string) => {
    if (str.endsWith("from")) {
      return str.slice(0, -4);
    } else if (str.endsWith("to")) {
      return str.slice(0, -2);
    }
    return str;
  };

  const truncateMiddle = (fullStr: string, strLen: number, separator: string = "...") => {
    if (fullStr.length <= strLen) return fullStr;

    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
    );
  };

  const renderCellContent = (cell: any): JSX.Element => {
    if (cell.info.header === "from" || cell.info.header === "to") {
      const elementType = getElementType(cell.value);
      if (elementType === "chains") {
        return (
          <TruncateText
            text={cell.value.toString()}
            maxLength={20}
            isBlockChainExplorerEnabled={true}
          />
        );
      }
      if (elementType === "accounts") {
        return (
          <span title={cell.value}>
            {ParseName([cell.value] ?? "", "Account").resourceId || "NA"}
          </span>
        );
      }
      if (elementType === "accounts-isArray") {
        return (
          <span title={cell.value}>
            {removeEndingAndUpperCase(ParseName(cell.value ?? "", "Account").resourceId || "NA")}
          </span>
        );
      }
      if (elementType === "chains-isArray") {
        const chainAssetName = processChainAdressString(cell.value);
        return <span title={cell.value}>{chainAssetName}</span>;
      } else {
        return <span title={cell.value}>{cell.value}</span>;
      }
    } else if (cell.info.header === "asset") {
      const elementType = getElementType(cell.value);

      if (elementType === "chains") {
        return <AssetCell assetName={cell.value} />;
      } else {
        const chainAssetName = processAssetString(cell.value);

        return <span title={cell.value}>{chainAssetName}</span>;
      }
    } else if (cell.info.header === "actions") {
      return (
        <div>
          <Button
            size="sm"
            kind="primary"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRow(cell.value);
              navigate("/policy/creator", { state: { selectedRow: cell.value } });
            }}
          >
            Edit
          </Button>
          <Button
            size="sm"
            kind="danger"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRow(cell.value);
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return <span title={cell.value}>{cell.value}</span>;
    }
  };

  const handleDeleteSubmit = () => {
    if (selectedRow?.name) {
      deleteRule.mutate(selectedRow.name);
    }
    setIsDeleteModalOpen(false);
  };

  const handleRowClick = (rowId: string) => {
    const selectedTransferRule = filteredRows.find((row) => row.id === rowId);
    if (selectedTransferRule) {
      navigate(`/policy/${selectedTransferRule.actions.name}`);
    }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={handleSearchChange}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={paginatedRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    // @ts-expect-error error
                    key={row.id}
                    {...getRowProps({ row })}
                    onClick={() => handleRowClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
      {isDeleteModalOpen && (
        <Modal
          open={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          onRequestSubmit={handleDeleteSubmit}
          modalHeading="Delete Transfer Rule"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        ></Modal>
      )}
    </>
  );
};

export default PolicyTable;

function AssetCell({ assetName }: { assetName: string }) {
  const { data: asset, isLoading } = useAsset({ assetId: assetName });

  if (isLoading) return <span>Loading...</span>;

  return (
    <span>
      {asset &&
        (asset.symbol
          ? ParseName([asset.symbol], "Symbol").resourceId
          : ParseName([asset.name!], "Asset").resourceId)}
    </span>
  );
}
