import { useState, useCallback } from "react";

interface UsePaginationProps {
  initialPageSize?: number;
  onPageChange?: (page: number, pageSize: number) => void;
}

interface UsePaginationReturn {
  page: number;
  pageSize: number;
  pageSizes: number[];
  totalItems: number;
  setTotalItems: (total: number) => void;
  handlePageChange: (event: { page: number; pageSize: number }) => void;
  setPage: (page: number) => void;
  pageToken: string | undefined;
  setPageToken: (token: string | undefined) => void;
  prevPageTokens: string[];
  setPrevPageTokens: (tokens: string[]) => void;
}

const usePagination = ({
  initialPageSize = 15,
  onPageChange,
}: UsePaginationProps = {}): UsePaginationReturn => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [prevPageTokens, setPrevPageTokens] = useState<string[]>([]);

  const pageSizes = [5, 15, 25, 50, 100];

  const handlePageChange = useCallback(
    ({ page: newPage, pageSize: newPageSize }: { page: number; pageSize: number }) => {
      if (newPageSize !== pageSize) {
        setPageSize(newPageSize);
        setPage(1);
        setPageToken(undefined);
        setPrevPageTokens([]);
      } else {
        setPage(newPage);
      }

      if (onPageChange) {
        onPageChange(newPage, newPageSize);
      }
    },
    [pageSize, onPageChange],
  );

  return {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage,
    pageToken,
    setPageToken,
    prevPageTokens,
    setPrevPageTokens,
  };
};

export default usePagination;
