import React from "react";
import CPage from "../../components/CPage";

import RoleTable from "./components/RoleTable";
import { useRole } from "./api/getRoles";

export default function RolesPage() {
  const { data: role } = useRole();

  return (
    <CPage title="Roles">
      {role && role.roles ? <RoleTable rolePage={role} /> : <p>No Roles</p>}
    </CPage>
  );
}
