import { Outlet } from "react-router-dom";
import CPage from "../../components/CPage";
import { useGetAssetPage } from "./api/getAssetPage";
import AssetTable from "./components/AssetTable";
import { useUser, useWebAuthnUser } from "../usersManagement/api/useUsers";

export default function AssetsPage() {
  const assetPage = useGetAssetPage({ pageSize: 100 });
  const testUser = useUser();
  const webauthnUser = useWebAuthnUser();
  console.log("testUser", testUser);
  console.log("webauthnUser", webauthnUser);
  console.log("assetPage", assetPage);
  return (
    <CPage title="Assets" primaryAction={{ title: "Add asset", url: "./create" }}>
      <AssetTable assetdata={assetPage.data} />
      <Outlet />
    </CPage>
  );
}
