/* eslint-disable */
import { ed25519 } from "@noble/curves/ed25519";
import {
  ContentDigest,
  SerializeHeaders,
  SigParams,
  Signature,
  SignatureBase,
  TreasuryHeader,
} from "./http_signatures";
import { RegisteredCredential, WebauthnClient, WebauthnUser } from "./webauthn";
import { hex } from "@scure/base";
import {
  Credential,
  CredentialPage,
  CredentialVariant,
  ErrorResponse,
  GetTypeMeta,
  Method,
  Operation,
  OperationPage,
  ResourceVariant,
  ResourceTypes,
  User,
  UserPage,
  Address,
  RolePage,
  AddressPage,
  Role,
  ChainPage,
  AccountPage,
  Account,
  Asset,
  AssetPage,
  Transfer,
  TransferPage,
  TransferRulePage,
  TransferRule,
  TreasuryPage,
  SymbolResource,
  Treasury,
  TransactionPage,
} from "./types";
import { Name, ParseName, ParseNameDropResourceId } from "./name";
import { RequestError, NameError } from "./errors";
import { Signer, SessionSigner, WebauthnSigner } from "./signers";

const EXTENSION_SEPARATOR = "+";

interface HttpClientArgs {
  webauthnClient?: WebauthnClient;
  version?: number;

  timeout_milliseconds?: number;
  treasuryId?: string;

  // When signed http request initially done being submitted
  onSignedHttpSubmit?: (operation_name?: string, err?: Error) => void;

  // called when the operation state changes.
  // this will be called also when operation is finally in succeeded or failed state.
  // If the operation times out, this will error.
  onOperationUpdate?: (operation?: Operation, err?: Error) => void;

  // called when a resource is done being created or updated.
  // if the operation fails or times out, this will error.
  onResourceUpdate?: (response?: any, err?: Error) => void;
}

export interface requestArgs {
  signer?: Signer;
  query?: URLSearchParams;
  filter?: string;

  // for listing
  page_size?: number;
  page_token?: string;

  // Id / name of operation to approve
  approve?: string;
  // Id / name of operation to challenge / cancel
  challenge?: string;
}
interface requestArgsWithSigner extends requestArgs {
  signer: Signer;
  // // query?: URLSearchParams;
  // // // Id / name of operation to approve
  // // approve?: string
  // // // Id / name of operation to challenge / cancel
  // challenge?: string
}

export class Result<T> {
  resource?: T;
  operation: Operation;
  constructor(operation: Operation, resource?: T) {
    this.resource = resource;
    this.operation = operation;
  }

  success(): boolean {
    return this.operation.state == "succeeded";
  }
  isAuthorizing(): boolean {
    return this.operation.state == "authorizing";
  }
  getResource(): T {
    if (!this.success()) {
      throw Error("should not deference resource is operation didn't succeed");
    }
    if (this.operation.request?.action == "delete") {
      throw Error("should not deference resource after a delete request");
    }
    return this.resource!;
  }
}

interface SessionCred {
  privateKey: Uint8Array;
  credential: Credential;
}

export class HttpClient {
  sessionCredential?: SessionCred;
  sessionTimeoutHandle?: NodeJS.Timeout;

  webauthnClient: WebauthnClient;
  version: number;
  timeout_milliseconds: number;

  last_timestamp: number;
  origin: string;
  treasuryId: string;

  onSignedHttpSubmit?: (operation_name?: string, err?: Error) => void;
  onOperationUpdate?: (operation?: Operation, err?: Error) => void;
  onResourceUpdate?: (response?: any, err?: Error) => void;

  constructor(origin: string, args: HttpClientArgs) {
    if (origin == undefined || origin == "") {
      // origin = "http://localhost:8777";
      console.warn("no api url set, defaulting to", origin);
    }
    this.origin = origin;
    this.webauthnClient = args.webauthnClient!;
    this.version = args.version || 1;
    this.treasuryId = args.treasuryId || "";
    this.onSignedHttpSubmit = args.onSignedHttpSubmit;
    this.onOperationUpdate = args.onOperationUpdate;
    this.onResourceUpdate = args.onResourceUpdate;
    this.timeout_milliseconds = args.timeout_milliseconds || 15_000;
    this.last_timestamp = 0;
  }

  setUser(user: WebauthnUser) {
    this.webauthnClient.setUser(user);
  }

  // rotateSessionKey(): Uint8Array {

  // }

  queryString(args?: requestArgs): string {
    if (args != undefined) {
      const params = new URLSearchParams(args.query);

      // handle common query args
      if (args.page_size != undefined) {
        params.set("page_size", `${args.page_size}`);
      }
      if (args.page_token != undefined) {
        params.set("page_token", `${args.page_token}`);
      }
      if (args.filter != undefined) {
        params.set("filter", `${args.filter}`);
      }
      return params.toString();
    }
    return "";
  }

  async defaultArgs(args?: requestArgs): Promise<requestArgsWithSigner> {
    if (args == undefined) {
      args = {};
    }
    if (args.signer == undefined) {
      if (this.sessionCredential == undefined) {
        await this.registerSession();
        if (this.sessionCredential == undefined) {
          // this should not happen
          throw Error("failed to register session");
        }
      }
      args.signer = new SessionSigner(this.sessionCredential.privateKey, {
        public_key: this.sessionCredential.credential.public_key!,
      });
    }
    return args as requestArgsWithSigner;
  }

  async get<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    resourceType: T,
    name: Name,
    query?: requestArgs,
  ): Promise<R> {
    // console.log("get", resourceType, name, query);
    const parsedName = ParseName(name, resourceType);
    return await this.get_direct(parsedName.name, query);
  }

  async list<T extends ResourceVariant, P extends ResourceTypes[T]["Page"]>(
    resourceType: T,
    query?: requestArgs,
  ): Promise<P> {
    const typeMeta = GetTypeMeta(resourceType);

    return await this.list_direct(typeMeta.resourceField, query);
  }

  async nested_list<T extends ResourceVariant, P extends ResourceTypes[T]["Page"]>(
    resourceType: T,
    name: Name,
    query?: requestArgs,
  ): Promise<P> {
    if (name === undefined) {
      throw new Error("Name is required for nested_list method.");
    }
    const parsedName = ParseNameDropResourceId(name, resourceType);
    return await this.list_direct(parsedName.name, query);
  }

  async create<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    // "User"
    resourceType: T,
    name: Name,
    resourceData: R,
    args?: requestArgs,
  ): Promise<Result<R>> {
    const validatedArgs = await this.defaultArgs(args);
    return await this.signedFetch(resourceType, "POST", name, resourceData, validatedArgs);
  }

  async update<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    resourceType: T,
    name: Name,
    resourceData: R,
    args?: requestArgs,
  ): Promise<Result<R>> {
    const validatedArgs = await this.defaultArgs(args);
    console.log("*********************");
    console.log(name, validatedArgs, resourceData);
    return await this.signedFetch(resourceType, "PUT", name, resourceData, validatedArgs);
  }

  async delete<T extends ResourceVariant>(
    resourceType: T,
    name: Name,
    args?: requestArgs,
  ): Promise<Result<any>> {
    const validatedArgs = await this.defaultArgs(args);
    return await this.signedFetch(resourceType, "DELETE", name, {}, validatedArgs);
  }

  // get without the name safe typing
  async get_direct<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    name: string,
    query?: requestArgs,
  ): Promise<R> {
    const response = await this.fetch(name, query, {
      method: "GET",
    });
    return response;
  }

  // list without the name safe typing
  async list_direct<T extends ResourceVariant, P extends ResourceTypes[T]["Page"]>(
    name: string,
    query?: requestArgs,
  ): Promise<P> {
    const response = await this.fetch(name, query, {
      method: "GET",
    });
    return response;
  }

  // // nested list without the name safe typing
  // async nested_list_direct<T extends Resource, R extends ResourceTypes[T]["Page"]>(name: string, query?: requestArgs): Promise<R> {
  //   const response = await this.fetch(name, query, {
  //     method: "GET",
  //   });
  //   return response;
  // }

  async fetch(path: string, args?: requestArgs, init?: RequestInit): Promise<any> {
    let origin = this.origin;
    if (origin.endsWith("/")) {
      origin = origin.slice(0, origin.length - 1);
    }
    let url = path;
    if (path.indexOf(origin) == -1) {
      // TODO detect HTTPS?
      if (path.indexOf(`/v${this.version}`) == 0) {
        url = `${origin}${path}`;
      } else {
        url = `${origin}/v${this.version}/${path}`;
      }
    }
    const q = this.queryString(args);
    if (q != "") {
      url = url + `?${q}`;
    }

    console.log("fetching:::::::::::: ", url);
    const res = await fetch(url, init);
    const json = await res.json();
    // Detect and throw error
    if (res.status != 200) {
      const err = json as ErrorResponse;
      // console.log(err);
      throw new RequestError(err.message, err.status, err.code);
    }
    return json;
  }

  // Helpers

  // Address
  async createAddress(
    name: Name,
    resourceData: Address,
    query?: requestArgs,
  ): Promise<Result<Address>> {
    return this.create("Address", name, resourceData, query);
  }

  async getAddress(name: Name, query?: requestArgs): Promise<Address> {
    return this.get("Address", name, query);
  }

  async listAddresses(query?: requestArgs): Promise<AddressPage> {
    return this.list("Address", query);
  }

  async updateAddress(name: Name, resourceData: Address): Promise<Result<Address>> {
    return this.update("Address", name, resourceData);
  }

  async deleteAddress(name: Name, query?: requestArgs): Promise<Result<Address>> {
    return this.delete("Address", name, query);
  }

  async getUser(name: Name, query?: requestArgs): Promise<User> {
    return await this.get("User", name, query);
  }
  async listUser(query?: requestArgs): Promise<UserPage> {
    return await this.list("User", query);
  }

  async updateUser(name: Name, resourceData: User): Promise<Result<User>> {
    return this.update("User", name, resourceData);
  }

  async createCredential(
    name: Name,
    resourceData: Credential,
    query?: requestArgs,
  ): Promise<Result<Credential>> {
    return this.create("Credential", name, resourceData, query);
  }

  async getCredential(name: Name, query?: requestArgs): Promise<Credential> {
    return this.get("Credential", name, query);
  }

  async listCredential(query?: requestArgs): Promise<CredentialPage> {
    return this.list("Credential", query);
  }

  async listUserCredential(user: Name, query?: requestArgs): Promise<CredentialPage> {
    return this.nested_list("Credential", user, query);
  }

  async getOperation(name: Name, query?: requestArgs): Promise<Operation> {
    return this.get("Operation", name, query);
  }
  async listOperation(query?: requestArgs): Promise<OperationPage> {
    return this.list("Operation", query);
  }

  async getRole(name: Name, query?: requestArgs): Promise<Role> {
    return this.get("Role", name, query);
  }
  async listRole(query?: requestArgs): Promise<RolePage> {
    return this.list("Role", query);
  }

  async listChains(query?: requestArgs): Promise<ChainPage> {
    return this.list("Chain", query);
  }

  async listAccounts(query?: requestArgs): Promise<AccountPage> {
    return this.list("Account", query);
  }
  async createAccount(
    name: Name,
    resourceData: Account,
    query?: requestArgs,
  ): Promise<Result<Account>> {
    return this.create("Account", name, resourceData, query);
  }

  async deleteAccount(name: Name, query?: requestArgs): Promise<Result<Account>> {
    return this.delete("Account", name, query);
  }

  async updateAccount(name: Name, resourceData: Account): Promise<Result<Account>> {
    return this.update("Account", name, resourceData);
  }

  async listTransfers(query?: requestArgs): Promise<TransferPage> {
    return this.list("Transfer", query);
  }

  //todo
  async listTransactions(query?: requestArgs): Promise<ChainPage> {
    return this.list("Transaction", query);
  }

  async listTransferTransactions(query?: requestArgs, name?: Name): Promise<TransactionPage> {
    return this.nested_list("Transaction", name!, query);
  }

  // await this.nested_list("Credential", [user.name!]);
  async createTransfer(
    name: Name,
    resourceData: Transfer,
    query?: requestArgs,
  ): Promise<Result<Transfer>> {
    return this.create("Transfer", name, resourceData, query);
  }

  async listAssets(query?: requestArgs): Promise<AssetPage> {
    return this.list("Asset", query);
  }

  async getAsset(name: Name, query?: requestArgs): Promise<Asset> {
    return this.get("Asset", name, query);
  }

  async deleteAsset(name: Name, query?: requestArgs): Promise<Result<Asset>> {
    return this.delete("Asset", name, query);
  }

  async createAsset(name: Name, resourceData: Asset, query?: requestArgs): Promise<Result<Asset>> {
    return this.create("Asset", name, resourceData, query);
  }

  async updateAsset(name: Name, resourceData: Asset, query?: requestArgs): Promise<Result<Asset>> {
    console.log("called");
    return this.update("Asset", name, resourceData, query);
  }

  async listTransferRules(query?: requestArgs): Promise<TransferRulePage> {
    return this.list("TransferRule", query);
  }

  async getTransferRule(name: Name, query?: requestArgs): Promise<TransferRule> {
    return this.get("TransferRule", name, query);
  }

  async listTreasuries(query?: requestArgs): Promise<TreasuryPage> {
    return this.list("Treasury", query);
  }
  async getTreasury(query?: requestArgs): Promise<Treasury> {
    let page = await this.list("Treasury", query);
    return page.treasuries![0];
  }

  async createSymbol(
    name: Name,
    resourceData: SymbolResource,
    query?: requestArgs,
  ): Promise<Result<SymbolResource>> {
    return this.create("Symbol", name, resourceData, query);
  }

  async createAdd0ess(
    name: Name,
    resourceData: Address,
    query?: requestArgs,
  ): Promise<Result<Address>> {
    return this.create("Address", name, resourceData, query);
  }

  async createTransferRule(
    name: Name,
    resourceData: TransferRule,
    query?: requestArgs,
  ): Promise<Result<TransferRule>> {
    console.log("createTransferRule", name, resourceData, query);
    return this.create("TransferRule", name, resourceData, query);
  }

  async updateTransferRule(name: Name, resourceData: TransferRule): Promise<Result<TransferRule>> {
    return this.update("TransferRule", name, resourceData);
  }

  async deleteTransferRule(name: Name, query?: requestArgs): Promise<Result<any>> {
    return this.delete("TransferRule", name, query);
  }

  async approve(operation: Operation, query: requestArgs): Promise<Result<any>> {
    const validatedArgs = await this.defaultArgs(query);
    if (validatedArgs.approve == undefined && validatedArgs.challenge == undefined) {
      throw TypeError("approve/challenge argument is required");
    }
    if (validatedArgs.approve != undefined) {
      validatedArgs.approve = operation.name!;
      validatedArgs.approve = validatedArgs.approve.replace("operations/", "");
    }

    if (validatedArgs.challenge != undefined) {
      validatedArgs.challenge = operation.name!;
      validatedArgs.challenge = validatedArgs.challenge.replace("operations/", "");
    }

    const resourceType = operation.request!.resource;
    const action = operation.request!.action!;

    let body = {};
    // not all operations have a body (e.g. custom operations)
    if (
      operation.request!.body &&
      operation.request!.body != null &&
      operation.request!.body.length > 0
    ) {
      body = JSON.parse(operation.request!.body!);
    }

    // is there a better way to do this??
    let method: Method = "POST";
    switch (action) {
      case "create":
        method = "POST";
        break;
      case "update":
        method = "PUT";
        break;
      case "delete":
        method = "DELETE";
        break;
      // case "generate":
      //   method = "POST";
      //   break;
    }

    let id = operation.request!.id;
    if (id == undefined && method != "POST") {
      throw Error("expected operation to have name set");
    }
    const parent = operation.request!.parent;
    let extension = operation.request!.extension;
    // TODO should use extension now, can delete in future
    if ((operation.request! as any)["distinguisher"]) {
      if (!extension) {
        extension = (operation.request! as any)["distinguisher"];
      }
    }

    if (extension && extension.length > 0) {
      id = `${id}` + EXTENSION_SEPARATOR + `${extension}`;
    }

    let name: Name = [];
    if (parent && parent.length > 0) {
      name = [parent];
    }
    if (id && id.length > 0) {
      if (name.length > 0) {
        name = [name[0]!, id!];
      } else {
        name = [id!];
      }
    }
    // console.log(method, name, body);

    return await this.signedFetch(resourceType, method, name, body as any, validatedArgs);
    // query.approve = operation.name!
  }

  // Helper for logging in
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getUserWebauthnCreds(username: string, query?: requestArgs): Promise<WebauthnUser> {
    const user = await this.getUser([username]);

    // gather all of the webauthn raw_id's.
    const webauthCreds: RegisteredCredential[] = [];
    const page = await this.nested_list("Credential", [user.name!]);
    for (const cred of page.credentials || []) {
      if (
        cred.variant == "web-authn" ||
        cred.variant == "web-authn-uv" ||
        (cred.variant as string) == "web-authn-with-uv"
      ) {
        if (cred.raw_id) {
          webauthCreds.push({
            name: cred.name!,
            public_key: cred.public_key!,
            raw_id: cred.raw_id,
            variant: cred.variant!,
          });
        }
      }
    }

    return {
      username: username,
      credentials: webauthCreds,
    };
  }

  //Check with Conor
  closeSession() {
    this.sessionCredential = undefined;
  }

  async registerSession(): Promise<Result<Credential>> {
    const signingUser = this.webauthnClient.getUser();
    if (signingUser == undefined) {
      throw new Error("must set current user");
    }
    // ed25519
    const privKey = ed25519.utils.randomPrivateKey();
    const pubKey = ed25519.getPublicKey(privKey);
    // .sessionCredential = privKey;
    // const pubkey = this.rotateSessionKey();
    if (this.sessionTimeoutHandle != undefined) {
      // clear timeout of any previous session
      clearTimeout(this.sessionTimeoutHandle);
    }
    this.sessionTimeoutHandle = setTimeout(
      () => {
        // Timeout in 1hrgi
        // TODO it'd be better to just query the state of the session credential instead...
        this.sessionCredential = undefined;
      },
      // timeout 10min on client before engine times out (1hr) to give some margin.
      1000 * 50 * 60,
    );
    const variant: CredentialVariant = "session";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const state = "";
    // let resource: CredentialData = {
    const resource: Credential = {
      variant: variant,
      // public keys are hex encoded
      public_key: hex.encode(pubKey),

      // have to set these but they are ignored
      name: "",
      create_time: new Date().toISOString(),
      // state: "",
      // state as("active" | "deleting"),
      version: 1,
    };

    const result = await this.createCredential([signingUser.username], resource, {
      // use webauthn to sign the transaction.
      signer: new WebauthnSigner(this.webauthnClient),
    });

    if (result.success()) {
      let cred = result.getResource();
      this.sessionCredential = {
        privateKey: privKey,
        credential: cred,
      };
      sessionStorage.setItem("session", JSON.stringify(cred));
    }

    return result;
  }

  async signedFetch<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    resourceType: T,
    method: Method,
    name: Name,
    resource: R,
    args: requestArgsWithSigner,
  ): Promise<Result<R>> {
    let path;
    if (method == "POST") {
      // For create/post, the resourceId is often optional.
      // If you do not set it, then treasury/engine will generate one.
      try {
        // try case where resourceId is passed
        const parsedName = ParseName(name, resourceType);
        path = `/v${this.version}/${parsedName.name}`;
      } catch (e) {
        if (!(e instanceof NameError)) {
          console.log("unexpected exception parsing name: ", e);
          throw e;
        }
        // resort to case where resourceId is not passed.
        const parsedName = ParseNameDropResourceId(name, resourceType);
        path = `/v${this.version}/${parsedName.name}`;
      }
    } else {
      const parsedName = ParseName(name, resourceType);
      path = `/v${this.version}/${parsedName.name}`;
    }

    // let nameId = normalize(username);
    // let name = `${fieldName}/${nameId}`;
    const queryString = this.queryString(args);
    const signingUser = this.webauthnClient.getUser();
    if (signingUser == undefined) {
      throw new Error("must set current user");
    }

    const body = JSON.stringify(resource);

    const contentDigest = new ContentDigest(body);
    let created = (Date.now() / 1000) | 0;
    if (created == this.last_timestamp) {
      // edge case that quick subsequent requests will use the same
      // timestamp if executed in the same second.  We increment to avoid
      // running into a sequence error.
      created++;
    }
    this.last_timestamp = created;
    const nonce = getRandomNumber(0, 10 ** 9);
    const keyid = args.signer.keyid();

    const sigParams = new SigParams({
      created: created,
      // username of user signing
      keyId: keyid,
      algorithm: args.signer.algorithm(),
      // must be an accurate WAL timestamp.
      nonce: nonce,
      approve: args.approve,
      cancel: args.challenge,
    });

    const base = new SignatureBase({
      // always use http as we don't support TLS termination?
      // scheme: "http",
      // authority: authority,
      contentDigest: contentDigest.asBase64(),
      method: method,
      params: sigParams,
      path: path,
      query: queryString,
      treasuryId: this.treasuryId,
    });

    const rawsig = await args.signer.sign(base.asUint8Array());
    const signature = new Signature(rawsig);
    const treasuryId = new TreasuryHeader(this.treasuryId);
    const headers = SerializeHeaders([contentDigest, sigParams, signature, treasuryId]);

    // console.log("body: ", body);
    // console.log("base: ", base.template());
    // console.log("headers: ", headers);
    let operation_name = args.approve || "";

    try {
      if (queryString != "") {
        path = path + `?${queryString}`;
      }
      const json = await this.fetch(path, undefined, {
        headers: headers,
        method: method,
        body: body || undefined,
      });
      // Could return name directly or as an object like {name: "operations/1234", ..}, not sure yet.
      if (typeof json == "string") {
        operation_name = json;
      } else {
        operation_name = json.name;
      }

      if (this.onSignedHttpSubmit) {
        this.onSignedHttpSubmit(operation_name);
      }
    } catch (e) {
      if (this.onSignedHttpSubmit) {
        this.onSignedHttpSubmit(undefined, e as Error);
        // TODO should try querying for the operation to get the exact error.
        const operation: Operation = {
          state: "failed",
          name: operation_name!,

          // not used
          initiator: "",
          approve: [],
          cancel: [],
        };
        console.log("unknown failure, should query operation: ", operation_name);
        return new Result(operation);
      } else {
        throw e;
      }
    }

    try {
      if (args.approve != undefined) {
        operation_name = args.approve;
      }
      const result: Result<R> = await this.awaitOperation(operation_name);
      return result;
    } catch (e) {
      if (this.onResourceUpdate) {
        this.onResourceUpdate(undefined, e as Error);
        const operation: Operation = {
          state: "failed",
          name: operation_name,
          // not used
          initiator: "",
          approve: [],
          cancel: [],
        };
        console.log("unknown failure, should query operation: ", operation_name);
        return new Result(operation);
      } else {
        throw e;
      }
    }
  }

  async customAction<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    resourceType: T,
    customAction: string,
    name: Name,
    args?: requestArgsWithSigner,
  ): Promise<Result<R>> {
    const validatedArgs = await this.defaultArgs(args);

    const parsedName = ParseName(name, resourceType);
    const path = `/v${this.version}/${parsedName.name}/${customAction}`;

    const body = JSON.stringify({});
    const contentDigest = new ContentDigest(body);
    const created = Math.floor(Date.now() / 1000);
    const nonce = getRandomNumber(0, 10 ** 9);
    const keyid = validatedArgs.signer.keyid();

    const sigParams = new SigParams({
      created,
      keyId: keyid,
      algorithm: validatedArgs.signer.algorithm(),
      nonce,
    });

    const base = new SignatureBase({
      contentDigest: contentDigest.asBase64(),
      method: "POST",
      params: sigParams,
      path,
      query: this.queryString(validatedArgs),
      treasuryId: this.treasuryId,
    });

    const rawsig = await validatedArgs.signer.sign(base.asUint8Array());
    const signature = new Signature(rawsig);
    const treasuryId = new TreasuryHeader(this.treasuryId);
    const headers = SerializeHeaders([contentDigest, sigParams, signature, treasuryId]);

    try {
      const json = await this.fetch(path, undefined, {
        headers,
        method: "POST",
        body: body,
      });

      let operationName = typeof json === "string" ? json : json.name;
      if (this.onSignedHttpSubmit) {
        this.onSignedHttpSubmit(operationName);
      }

      const result: Result<R> = await this.awaitOperation(operationName);
      console.log("retry transfer result: ", result);
      return result;
    } catch (e) {
      if (this.onSignedHttpSubmit) {
        this.onSignedHttpSubmit(undefined, e as Error);
      }
      throw e;
    }
  }
  async retryTransfer(id: string, args?: requestArgsWithSigner): Promise<Result<Transfer>> {
    return await this.customAction("Transfer", "retry", id, args);
  }
  async cancelTransfer(id: string, args?: requestArgsWithSigner): Promise<Result<Transfer>> {
    return await this.customAction("Transfer", "cancel", id, args);
  }

  async awaitOperation<T extends ResourceVariant, R extends ResourceTypes[T]["Resource"]>(
    operation_name: string,
  ): Promise<Result<R>> {
    // this
    // check to see if we are approving a different transaction...
    // if (operation.request?.operation) {
    //   name = `operations/${operation.request.operation}`;
    // }

    const timeout = this.timeout_milliseconds;
    const start = new Date().getTime();
    let last_state = "";
    // if (this.onOperationUpdate) {
    //   this.onOperationUpdate(operation);
    // }
    // this polls for the operation to finish.
    /*eslint no-constant-condition: "off"*/
    while (true) {
      const now = new Date().getTime();

      const operation = await this.getOperation([operation_name]);
      if (operation.state != last_state) {
        last_state = operation.state!;
        if (this.onOperationUpdate) {
          this.onOperationUpdate(operation);
        }
      }
      // console.log("operation: ", operation);
      switch (operation.state) {
        case "succeeded": {
          if (operation.request?.action == "delete") {
            // if we're deleting something, there's nothing to get anymore.
            return new Result(operation);
          }

          let resource_name = "";
          if (operation.response && operation.response.name) {
            resource_name = operation.response.name;
          } else {
            // older way of getting it
            resource_name = (operation as any).resource_name;
          }

          // console.log("get operation resource name: ", resource_name);
          const resource: R = await this.get_direct(resource_name);
          if (this.onResourceUpdate) {
            this.onResourceUpdate(resource);
          }
          return new Result(operation, resource);
        }
        case "creating-resource":
          if (now - start > timeout) {
            throw Error("operation not yet complete, it may complete later");
          }
          // wait...
          await new Promise((r) => setTimeout(r, 150));
          break;
        // Currently, "undefined" means the default state, which is "authorizing".
        case "authorizing" || undefined: {
          console.log("operation waiting on policy");
          return new Result(operation);
        }
        default: {
          let err = new RequestError("operation failed", "Unknown", 2);
          if (operation.error) {
            err = new RequestError(
              operation.error.message,
              operation.error.status,
              operation.error.code,
            );
          }
          if (this.onResourceUpdate) {
            this.onResourceUpdate(undefined, err);
          } else {
            throw err;
          }
          console.log("operation waiting on some event ", operation);
          return new Result(operation);
        }
      }
    }
  }
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
function getRandomNumber(min: number, max: number): number {
  return (Math.random() * (max - min) + min) | 0;
}
