import { httpClientInstance } from "../../clientInstance";
import { requestArgs } from "../api/api";
import { ChainList } from "./connector/types";
import { Deposit, DepositPage } from "./oracle/types";

export const ConnectorUrl = `https://connector.cordialapis.com`;
export const OracleUrl = `https://oracle.cordialapis.com`;

export class CordialApis {
  network: string;
  queryString: (args?: requestArgs) => string;
  constructor(network: string, queryString: (args?: requestArgs) => string) {
    this.network = network;
    this.queryString = queryString;
  }

  async fetchChains(): Promise<ChainList> {
    const res = await fetch(`${ConnectorUrl}/v1/chains`, {
      headers: {
        network: this.network,
      },
    });
    const data = await res.json();
    return data as ChainList;
  }

  async fetchDepositsPage(query?: requestArgs, treasuryId?: string): Promise<DepositPage> {
    let url = `${OracleUrl}/v1/deposits`;
    const q = this.queryString(query);
    if (q != "") {
      url = url + `?${q}`;
    }
    const headers: Record<string, string> = {
      network: this.network,
    };
    if (window.FIXED_ORGANIZATION) {
      headers["Organization"] = window.FIXED_ORGANIZATION;
    } else if (treasuryId) {
      headers["Treasury"] = treasuryId;
    }
    const res = await fetch(url, {
      headers,
      credentials: "include",
    });
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data as DepositPage;
  }

  async fetchDepositDetails(name: string, treasuryId?: string): Promise<Deposit> {
    const url = `${OracleUrl}/v1/${name}`;
    const headers: Record<string, string> = {
      network: this.network,
    };
    if (treasuryId && process.env.NODE_ENV !== "development") {
      headers["Treasury"] = treasuryId;
    }
    if (process.env.NODE_ENV === "development") {
      headers["Organization"] = "2gVJksO6IrLz6QDfADasHk21UAn";
    }
    const res = await fetch(url, {
      headers,
      credentials: "include",
    });
    const data = await res.json();
    if (!res.ok) throw new Error(data.message);
    return data as Deposit;
  }
}

async function createHttpClient() {
  const treasury = await httpClientInstance.getTreasury();
  const network = treasury.network || "";

  return new CordialApis(network, httpClientInstance.queryString);
}

export const cordialApisClient = await createHttpClient();
