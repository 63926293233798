import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { TransferPage } from "../../../lib/api/types";

export const fetchTransfersPage = (
  pageSize: number = 25,
  pageToken?: string,
  filter?: string,
): Promise<TransferPage> => {
  return httpClientInstance.listTransfers({ page_size: pageSize, page_token: pageToken, filter });
};

interface UseTransfersPageOptions {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export const useTransfersPage = ({
  pageSize = 25,
  pageToken,
  filter,
}: UseTransfersPageOptions = {}) => {
  const queryKey = useMemo(
    () => ["transfersPage", pageSize, pageToken, filter],
    [pageSize, pageToken, filter],
  );

  const { data, error, isError, isLoading } = useQuery<TransferPage, Error>({
    queryKey,
    queryFn: () => fetchTransfersPage(pageSize, pageToken, filter),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (isError && error) {
      console.error("Error fetching transfersPage:", error);
    }
  }, [isError, error]);

  return { data: data || null, error: isError ? error : null, isLoading };
};
