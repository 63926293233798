import React, { useEffect, useState } from "react";
import { ContentSwitcher, Switch, FilterableMultiSelect } from "@carbon/react";
import { ParseName } from "../../../../lib/api/name";

interface SelectItem {
  label: string;
  value: string;
}

interface AccountAddressFromSelectorProps {
  accountAndAddressModeFrom: string;
  accountOptions: SelectItem[];
  addressList: SelectItem[];
  setAccountAndAddressModeFrom: (mode: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  resetMultiSelectFrom: () => void;
  setSelectedAccountsFrom: (accounts: SelectItem[]) => void;
  setSelectedAddressesFrom: (addresses: SelectItem[]) => void;
  accountKeyFrom: number;
  addressKeyFrom: number;
  selectedRow: any;
}

const AccountAddressFromSelector: React.FC<AccountAddressFromSelectorProps> = ({
  accountAndAddressModeFrom,
  accountOptions,
  addressList,
  setAccountAndAddressModeFrom,
  setFieldValue,
  resetMultiSelectFrom,
  setSelectedAccountsFrom,
  setSelectedAddressesFrom,
  accountKeyFrom,
  addressKeyFrom,
  selectedRow,
}) => {
  const [initialAccounts, setInitialAccounts] = useState<SelectItem[]>([]);
  const [initialAddresses, setInitialAddresses] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (selectedRow && selectedRow.from) {
      if (Array.isArray(selectedRow.from)) {
        const isAccount = selectedRow.from.some((item: string) => item.startsWith("accounts/"));
        const isAddress = selectedRow.from.some((item: string) => item.startsWith("chains/"));

        if (isAccount) {
          setAccountAndAddressModeFrom("account");
          const selectedAccounts = selectedRow.from.map((account: string) => {
            const matchedAccount = accountOptions.find((option) => option.value === account);
            return matchedAccount || { label: account, value: account };
          });
          setInitialAccounts(selectedAccounts);
          setSelectedAccountsFrom(selectedAccounts);

          setFieldValue("accountFrom", selectedAccounts.map((item: any) => item.value).join(","));
        } else if (isAddress) {
          setAccountAndAddressModeFrom("address");
          const selectedAddresses = selectedRow.from.map((address: string) => {
            //@ts-expect-error name exist
            const matchedAddress = addressList.find((option) => option.name === address);
            return (
              {
                //@ts-expect-error name exist
                label: matchedAddress.name,
                value: matchedAddress,
              } || { label: address, value: address }
            );
          });
          setInitialAddresses(selectedAddresses);
          setSelectedAddressesFrom(selectedAddresses);
          //@ts-expect-error name exist
          setFieldValue("addressFrom", selectedAddresses.map((item) => item.value).join(","));
        }
      } else if (selectedRow.from === "any/address") {
        setAccountAndAddressModeFrom("all");
        resetMultiSelectFrom();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  console.log("initialAddresses", initialAddresses);

  const extractDescription = (item: SelectItem): string => {
    //@ts-expect-error err
    const notes = item?.value.notes;
    if (!notes) return "";

    if (typeof notes === "object" && notes.description) {
      return `${notes.description}`;
    }

    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        return data.description || "";
      } catch (error) {
        return notes;
      }
    }

    return "";
  };

  const ItemRendererComponent: React.FC<{ item: SelectItem }> = ({ item }) => {
    const parsedName = ParseName(item.label || "", "Address");
    const resourceId = parsedName.resourceId;
    const parentId = parsedName.parentId;
    const description = extractDescription(item);

    return (
      <div>
        <div>{resourceId}</div>
        <div style={{ display: "flex", gap: "100px" }}>
          <div>
            <strong>Chain:</strong> {parentId}
          </div>
          <div>
            <strong>Description:</strong> {description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>
          What Accounts / Addresses From would you like this Rule to apply to?
        </div>
        <ContentSwitcher
          onChange={(e) => {
            const selectedMode = e.name;
            setAccountAndAddressModeFrom(selectedMode as string);
            setFieldValue("accountAndAddressModeFrom", selectedMode);
          }}
          selectedIndex={
            accountAndAddressModeFrom === "address"
              ? 0
              : accountAndAddressModeFrom === "account"
                ? 1
                : 2
          }
          size="sm"
        >
          <Switch name="address" text="Address" />
          <Switch name="account" text="Account" />
          <Switch name="all" text="All" />
        </ContentSwitcher>
      </div>

      {accountAndAddressModeFrom !== "all" && (
        <div style={{ marginBottom: "20px" }}>
          {accountAndAddressModeFrom === "account" && (
            <FilterableMultiSelect
              key={accountKeyFrom}
              id="accountFrom"
              titleText="Select Account From"
              placeholder="Choose an account"
              //@ts-expect-error err
              items={accountOptions}
              //@ts-expect-error err
              initialSelectedItems={initialAccounts}
              //@ts-expect-error err
              itemToString={(item: SelectItem) => (item ? item.label : "")}
              onChange={(e) => {
                //@ts-expect-error err
                const selectedAccountsValues = e.selectedItems.map((item: SelectItem) => item);
                setSelectedAccountsFrom(selectedAccountsValues);
                setFieldValue(
                  "accountFrom",
                  selectedAccountsValues.map((item) => item.value).join(","),
                );
              }}
            />
          )}

          {accountAndAddressModeFrom === "address" && (
            <div style={{ marginTop: "20px" }}>
              <FilterableMultiSelect
                key={addressKeyFrom}
                id="addressFrom"
                titleText="Select Address From"
                placeholder="Choose an address"
                //@ts-expect-error err
                items={addressList.map((address) => ({
                  //@ts-expect-error err
                  label: address.name,
                  value: address,
                }))}
                //@ts-expect-error err
                initialSelectedItems={initialAddresses}
                //@ts-expect-error err
                itemToString={(item) => (item ? item.label : "")}
                itemToElement={(item) => <ItemRendererComponent item={item as SelectItem} />}
                onChange={(e) => {
                  //@ts-expect-error err
                  const selectedAddressValues = e.selectedItems.map((item: SelectItem) => item);
                  setSelectedAddressesFrom(selectedAddressValues);
                  setFieldValue(
                    "addressFrom",
                    selectedAddressValues.map((item) => item.value).join(","),
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountAddressFromSelector;
