import TransferTable from "./component/TransferTable";
import { useWebAuthnUser } from "../usersManagement/api/useUsers";
import CPage from "../../components/CPage";
import { Outlet } from "react-router-dom";

export default function TransfersPage() {
  const webauthnUser = useWebAuthnUser();

  console.log("webauthnUser", webauthnUser);
  return (
    <CPage
      title="Transfers"
      secondaryActions={[{ title: "Historical transfers", url: "/deposits" }]}
      primaryAction={{ title: "Create transfer", url: "./create" }}
    >
      <TransferTable />
      <Outlet />
    </CPage>
  );
}
