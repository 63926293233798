import { useMutation } from "@tanstack/react-query";
import CPage from "../../components/CPage";

import { useUser } from "./api/useUsers";
import RegisterCredentialForm from "./components/form/RegisterCredentialForm";
import { registerInviteCode } from "./services/registerInviteCode";
import { useNotificationStore } from "../../stores/notifications";

export default function RegisterCredentialsPage() {
  const currentUser = useUser();

  const { addNotification } = useNotificationStore();

  const postCrendentialMutation = useMutation({
    mutationFn: async (invitationCode: string) => {
      return registerInviteCode(currentUser!, invitationCode);
    },
    onSuccess: () => {
      console.log("success you registered a credential");
      addNotification({
        type: "success",
        title: `Credential Registered successfully`,
      });
    },
    onError: (error) => {
      console.log("failure you did not register a credential", error);
      addNotification({
        type: "error",
        title: `${error}`,
      });
    },
  });

  return (
    <CPage title="Register Credentials">
      {currentUser && currentUser.name ? (
        <div>
          <RegisterCredentialForm registerCredential={postCrendentialMutation} />
          <span style={{ fontSize: "12px" }}> An admin can create an invite code for you.</span>
        </div>
      ) : (
        <p>No Credentials</p>
      )}
    </CPage>
  );
}
