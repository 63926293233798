import { ComboBox, Modal, TextInput, Toggle, ToastNotification } from "@carbon/react";
import React, { useEffect, useMemo, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import useCachedState from "../../hooks/useCachedState";
import { useUsers } from "../usersManagement/api/useUsers";
import { ParseName } from "../../lib/api/name";
import { User } from "@/lib/api/types";
import { KEY_API, KEY_ORGANISATION, KEY_TREASURY, KEY_USER } from "lib/constants";
import { HttpClient } from "lib/api/api";
import { WebauthnClient } from "lib/api/webauthn";

export interface Item {
  id: number;
  text: string;
  userName?: string;
  icon?: string;
}

interface OrgTreasuryUserSelectorProps {
  handleToggleChange: () => void;
}

interface SelectError {
  title: string;
  message: string;
}

const OrgTreasuryUserSelector: React.FC<OrgTreasuryUserSelectorProps> = () => {
  //this may break pagination
  const cordialInstanceUsers = useUsers(1, 500, "");
  const { isSignedIn, user: clerkUser } = useUser();

  const [cordialInstanceUser, setCordialInstanceUser] = useState("");
  const [selectError, setSelectError] = useState<SelectError | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedTreasury, setCachedTreasury] = useCachedState<string>(KEY_ORGANISATION, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedOrganisation, setCachedOrganisation] = useCachedState<string>(KEY_TREASURY, "");
  const [cachedApiURL, setCachedApiURL] = useCachedState<string>(KEY_API, "");
  const [cachedUser, setCachedUser] = useCachedState<string>(KEY_USER, "");
  const [userName, setUserName] = useState(cachedUser);
  const [apiUrl, setApiUrl] = useState(cachedApiURL);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showInputs, setShowInputs] = useState(false);
  const handleToggleChange = () => setShowInputs(!showInputs);

  const areUserIdsEquivalent = (system1Id: string, system2Id: string): boolean => {
    const normalizedSystem1Id = system1Id.startsWith("users/")
      ? system1Id.split("/")[1]
      : system1Id;
    const normalizedSystem2Id = system2Id.startsWith("user_")
      ? system2Id.replace("user_", "")
      : system2Id;
    return normalizedSystem1Id === normalizedSystem2Id;
  };

  const handleGoBack = () => {
    setCachedApiURL("");
    setCachedUser("");
    setCachedOrganisation("");
    setCachedTreasury("");
    window.location.replace(`https://${window.location.host}`);
  };

  const matchingClerkUserCordialUser = useMemo(() => {
    if (!isSignedIn || !cordialInstanceUsers?.users) return [];
    return cordialInstanceUsers.users.filter((cordialUser) => {
      const clerkUserLabel = cordialUser.labels?.["sso-user"];
      return areUserIdsEquivalent(clerkUserLabel || "", clerkUser?.id || "");
    });
  }, [isSignedIn, cordialInstanceUsers?.users, clerkUser]);

  useEffect(() => {
    if (matchingClerkUserCordialUser.length === 1) {
      const user = matchingClerkUserCordialUser[0];
      const usr = ParseName(user.name || "", "User")?.resourceId;
      if (usr) {
        setCordialInstanceUser(usr);
        setUserName(usr);
        setIsModalOpen(false);
        setCachedUser(usr);
        window.location.replace(`https://${window.location.host}`);
      }
    }
  }, [matchingClerkUserCordialUser, setCachedUser]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCordialUserChange = (cordialUser: User | null) => {
    if (cordialUser && cordialUser.name) {
      const parsedName = ParseName(cordialUser.name, "User");
      if (parsedName) {
        const usr = parsedName.resourceId;
        setCordialInstanceUser(usr);
        setUserName(usr);
      }
    }
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleApiURLNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApiUrl(event.target.value);
  };

  const onConfirm = async () => {
    const httpClientInstance = new HttpClient(apiUrl, {
      webauthnClient: new WebauthnClient({}),
    });
    setSelectError(undefined);
    try {
      await httpClientInstance.getTreasury();
    } catch (e: any) {
      setSelectError({
        title: "Invalid API endpoint",
        message: (e as Error).toString(),
      });
      return;
    }

    try {
      await httpClientInstance.getUser(userName);
    } catch (e: any) {
      setSelectError({
        title: "Invalid user",
        message: (e as Error).message,
      });
      return;
    }

    if (showInputs) {
      apiUrl && setCachedApiURL(apiUrl);
      userName && setCachedUser(userName);
    } else {
      cordialInstanceUser && setCachedUser(cordialInstanceUser);
    }

    window.location.replace(`https://${window.location.host}`);
  };

  return (
    <Modal
      open={isModalOpen}
      modalHeading="Select your user"
      size="lg"
      primaryButtonText="Confirm"
      onRequestClose={handleCloseModal}
      onRequestSubmit={onConfirm}
      preventCloseOnClickOutside
      secondaryButtonText="Cancel"
      onSecondarySubmit={handleGoBack}
    >
      {!showInputs && (
        <div>
          <ComboBox
            id="user"
            titleText="Your users"
            placeholder="Select your user"
            items={matchingClerkUserCordialUser || []}
            itemToString={(item) => {
              return item ? ParseName(item.name || "", "User").resourceId : "";
            }}
            onChange={(event) => handleCordialUserChange(event.selectedItem as User | null)}
          />
        </div>
      )}
      {showInputs && (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <TextInput
            className="cds--form-item"
            labelText="API URL"
            name="apiURL"
            id="apiURL"
            value={apiUrl}
            onChange={handleApiURLNameChange}
            required
          />
          <TextInput
            className="cds--form-item"
            labelText="User"
            name="name"
            id="userNameInput"
            value={userName}
            onChange={handleUserNameChange}
            required
          />
        </div>
      )}
      <Toggle id="toggle-4" labelText="Manual" onToggle={handleToggleChange} />
      {selectError && (
        <ToastNotification
          kind="error"
          title={selectError.title}
          subtitle={selectError.message}
          hideCloseButton
        />
      )}
    </Modal>
  );
};

export default OrgTreasuryUserSelector;
