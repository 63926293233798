import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { Deposit } from "../../../lib/cordialapis/oracle/types";
import { cordialApisClient } from "../../../lib/cordialapis/api";
import useTreasury from "../../../features/treasuryManagement/getTreasuries";
import { ParseName } from "../../../lib/api/name";

async function fetchDepositDetails(name: string, treasuryId?: string): Promise<Deposit> {
  return cordialApisClient.fetchDepositDetails(name, treasuryId);
}

export const useDepositDetails = (name: string) => {
  const { data: treasuryPage, isLoading: isLoadingTreasury, error: errorTreasury } = useTreasury();

  const queryKey = useMemo(() => ["depositsDetails", name], [name]);

  const { data, error, isError, isLoading } = useQuery<Deposit, Error>({
    queryKey,
    queryFn: () =>
      fetchDepositDetails(
        name,
        treasuryPage?.treasuries?.[0]?.name
          ? ParseName(treasuryPage?.treasuries[0].name, "Treasury").resourceId
          : undefined,
      ),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    refetchInterval: 10000,
    enabled: !isLoadingTreasury && !errorTreasury,
  });

  useEffect(() => {
    if (isError && error) {
      console.error("Error fetching depositDetails:", error);
    }
  }, [isError, error]);

  return {
    data: data || null,
    error: isError ? error : errorTreasury ?? null,
    isLoading: isLoading || isLoadingTreasury,
  };
};
