import { Outlet } from "react-router-dom";

import { TransferRulePage } from "@/lib/api/types";

import { useTransferRulePage } from "./api/getTransferRules";
import PolicyTable from "./components/PolicyTable";
import { useWebAuthnUser, useUser } from "../usersManagement/api/useUsers";
import CPage from "../../components/CPage";

export default function PolicyPage() {
  const transferRules: TransferRulePage = useTransferRulePage() as TransferRulePage;
  console.log(transferRules);
  const testUser = useUser();
  const webauthnUser = useWebAuthnUser();
  console.log("testUser", testUser);
  console.log("webauthnUser", webauthnUser);

  return (
    <CPage title="Transfer Rules" primaryAction={{ title: "Create Rule", url: "/policy/creator" }}>
      <PolicyTable transferRulePage={transferRules} />
      <Outlet />
    </CPage>
  );
}
