import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Modal,
} from "@carbon/react";
import { useNotificationStore } from "../../stores/notifications";
import { useTransfersPage } from "./api/getTransfersPage";
import CPage from "../../components/CPage";
import "./TransferDetail.css";
import { useTransferTransactions } from "../transactionManagment/api/getTransferTransactions";
import TransferDetailsTable from "./component/TransferDetailsTable";
import { useRetryTransfer } from "../transactionManagment/api/rertyTransferTransaction";
import { useCancelTransfer } from "../transactionManagment/api/cancelTransferTransaction";
import { ParseName } from "../../lib/api/name";
import { formatDate } from "../../utils/formater";

const TransferDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>() ?? { id: "" };
  const transferId = id ?? "";
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const mutation = useCancelTransfer();

  const {
    data: transferPage,
    isLoading,
    error,
  } = useTransfersPage({ filter: `name="transfers/${id}"` });

  const {
    data: transactionPage,
    isLoading: isLoadingTransactions,
    error: errorTransactions,
  } = useTransferTransactions({ name: `transfers/${id}` });

  console.log("useTransferTransactions", transactionPage, isLoadingTransactions, errorTransactions);

  const { mutate: retry, error: isRetryError } = useRetryTransfer();

  const handleRetry = () => {
    retry(transferId);
  };

  console.log("useRetryTransfer", isRetryError);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = () => {
    // Handle delete logic here
    mutation.mutate(transferId);
  };

  useEffect(() => {
    if (error) {
      addNotification({
        type: "error",
        title: "Error loading transfer details",
        message: error.message,
      });
    }
  }, [error, addNotification]);

  return (
    <CPage title="Transfer Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/transfers")}>
          Back to Transfers
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" kind="tertiary" onClick={handleRetry}>
            Retry
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Abort
          </Button>
        </div>
      </div>

      <div>{isLoading ? "Loading..." : ""}</div>
      <div>{error ? "Error loading transfer details" : ""}</div>

      {!isLoading &&
        !error &&
        transferPage &&
        transferPage.transfers &&
        transferPage.transfers.length > 0 && (
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <StructuredListWrapper>
                  <StructuredListHead>
                    <StructuredListRow head></StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    <StructuredListRow>
                      <StructuredListCell head>Transfer Name</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].name
                          ? ParseName(transferPage.transfers[0].name, "Transfer").resourceId
                          : ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>State</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].state || ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Create Time</StructuredListCell>
                      <StructuredListCell>
                        {formatDate(transferPage.transfers[0]?.create_time ?? "")}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Update Time</StructuredListCell>
                      <StructuredListCell>
                        {formatDate(transferPage.transfers[0]?.update_time ?? "")}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Version</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].version || ""}
                      </StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
              <div style={{ flex: 1 }}>
                <StructuredListWrapper>
                  <StructuredListHead>
                    <StructuredListRow head></StructuredListRow>
                  </StructuredListHead>
                  <StructuredListBody>
                    <StructuredListRow>
                      <StructuredListCell head>From Address</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].name
                          ? ParseName(transferPage.transfers[0].from, "Address").resourceId
                          : ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>To Address</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].name
                          ? ParseName(transferPage.transfers[0].to, "Address").resourceId
                          : ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Asset</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].name
                          ? transferPage.transfers[0].symbol
                            ? ParseName(transferPage.transfers[0].symbol, "Symbol").resourceId
                            : ParseName(transferPage.transfers[0].asset, "Asset").resourceId
                          : ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Amount</StructuredListCell>
                      <StructuredListCell>
                        {transferPage.transfers[0].amount || ""}
                      </StructuredListCell>
                    </StructuredListRow>
                    <StructuredListRow>
                      <StructuredListCell head>Last Transaction</StructuredListCell>
                      <StructuredListCell>
                        {
                          // we migrated the transaction resource to not have a parent anymore
                          transferPage.transfers[0].last_transaction
                            ? (transferPage.transfers[0].last_transaction || "").split("/").pop()
                            : ""
                        }
                      </StructuredListCell>
                    </StructuredListRow>
                  </StructuredListBody>
                </StructuredListWrapper>
              </div>
            </div>
            <TransferDetailsTable />
          </div>
        )}

      <Modal
        open={isModalOpen}
        modalHeading="Cancel"
        primaryButtonText="Abort Transaction"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
      >
        <p>Are you sure you want to Abort: {id}?</p>
      </Modal>
    </CPage>
  );
};

export default TransferDetail;
