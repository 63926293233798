import { Transfer } from "@/lib/api/types";
import { ErrorFilled } from "@carbon/react/icons";
import React from "react";

interface StatusRendererProps {
  state: Transfer["state"];
  error?: Transfer["error"];
}

export const StatusRenderer: React.FC<StatusRendererProps> = ({ state, error }) => {
  return (
    <span
      title={error?.message || ""}
      style={{
        cursor: error ? "pointer" : "default",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      {error && (
        <ErrorFilled
          size={16}
          style={{ marginLeft: "4px", color: "#DA1E28" }}
          aria-label="Error"
          role="img"
        />
      )}
      <span style={{ marginLeft: error ? "10px" : "0" }}>{state}</span>
    </span>
  );
};
