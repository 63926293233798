import React, { useState, useEffect, ReactElement } from "react";
import { useParams } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { useAddressesPage } from "../api/getAddresses";
import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import { formatDate } from "../../../utils/formater";
import { ParseName } from "../../../lib/api/name";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination from "../../../components/pagination/CustomPagination";

const AccountDetailsAddressTable: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: addressesPage,
    isLoading: isLoadingAddresses,
    error: errorAddresses,
  } = useAddressesPage({ filter: `account = "accounts/${accountId}"` });

  const { page, pageSize, totalItems, setTotalItems, handlePageChange } = usePagination();

  useEffect(() => {
    if (addressesPage && addressesPage.total_size) {
      setTotalItems(addressesPage.total_size);
    }
  }, [addressesPage, setTotalItems]);

  const filteredRows = (addressesPage?.addresses || [])
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time ?? "") || "",
      notes: typeof item.notes === "object" ? item.notes.description : item.notes,
    }))
    .filter((row) => {
      return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
      );
    });

  const renderCellContent = (cell: any, row: any): any => {
    switch (cell.info.header) {
      case "name":
        return (
          <TruncateText
            text={cell.value.toString()}
            maxLength={30}
            isBlockChainExplorerEnabled={true}
          />
        );
      case "chain":
        if (IconCellRenderer) {
          return (
            (IconCellRenderer({
              value: ParseName([row.cells[2].value ?? ""], "Address").parentId || "",
            }) as ReactElement) || <></>
          );
        }
        return <>{cell.value}</>;
      default:
        return cell.value;
    }
  };

  if (isLoadingAddresses) {
    return <div>Loading...</div>;
  }

  if (errorAddresses) {
    return <div>Error: {errorAddresses.message}</div>;
  }

  return (
    <div>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={[
            { key: "create_time", header: "Created" },
            { key: "chain", header: "Chain" },
            { key: "name", header: "Name" },
            { key: "notes", header: "Description" },
            { key: "variant", header: "Variant" },
          ]}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // @ts-expect-error error
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell, row)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AccountDetailsAddressTable;
