import DepositTable from "./component/DepositTable";
import CPage from "../../components/CPage";
import { Outlet } from "react-router-dom";

export default function DepositsPage() {
  return (
    <CPage title="Deposits">
      <DepositTable />
      <Outlet />
    </CPage>
  );
}
