import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { ModalSubmitButton, MultiColumnRow, TextInput } from "../../../../components/form";
import { useModalSetters } from "../../../../components/CModal";

import { useUsers } from "../../api/useUsers";
import { ComboBox, Checkbox } from "@carbon/react";
import { useSession } from "@clerk/clerk-react";
import useAccessToken from "../../../../hooks/useAccessToken";
import { useQueryClient } from "@tanstack/react-query";
import useRegisterUser from "../../api/createRegisterUser";
import { useRole } from "../../api/getRoles";
import { Role } from "@/types/cordialsys/policy/iam/user";
import { ParseName } from "../../../../lib/api/name";
import useAvailableUsers from "../../hooks/useAvailableUsers";
import { Warning } from "@carbon/react/icons";
import useCachedState from "../../../../hooks/useCachedState";
import { KEY_TREASURY } from "../../../../lib/constants";

interface FormValues {
  newUserName: string;
  ssoUser: string;
  roles: string[];
}

interface CheckboxGroupProps {
  roles: Role[];
}

export default function CombinedForm() {
  const availableUsers = useAvailableUsers();
  const users = useUsers(1, 500, "");
  const modalSetters = useModalSetters();
  const queryClient = useQueryClient();
  const { mutate: registerUserMutate } = useRegisterUser();
  const rolesPage = useRole();
  const roles = rolesPage.data?.roles || [];
  const [showInputs] = useState(false);
  const [userIdentification, setUserIdentification] = useState<string>("");
  const [SSOIdentification, setSSOIdentification] = useState<string>("");
  const [cachedOrganisation] = useCachedState<string>(KEY_TREASURY, "");

  const { session } = useSession();
  useAccessToken(session);

  const initialValues: FormValues = {
    newUserName: "",
    ssoUser: "",
    roles: [],
  };

  const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (noUsersToAdd) return errors;
    if (!values.newUserName) {
      errors.newUserName = "User name is required.";
    }

    return errors;
  };

  const handleUsernameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserIdentification(event.target.value);
  };

  const getEmailPrefix = (email: string): string => {
    const [prefix] = email.split("@");
    return prefix;
  };

  const handleUserSSOInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ssoId = event.target.value;
    setSSOIdentification(ssoId);
  };

  const handleComboChange = (selectedItem: any, formikProps: any) => {
    const userId = selectedItem ? getEmailPrefix(selectedItem.object.emails[0]) : "";
    const ssoId = selectedItem.id ? getEmailPrefix(selectedItem.id) : "";
    setUserIdentification(userId);
    formikProps.setFieldValue("newUserName", userId);
    formikProps.setFieldValue("ssoUser", ssoId);
  };

  const handleSubmit = (values: FormValues, modalSetters: any) => {
    const { setLoading, setNotif } = modalSetters;
    setLoading(true);
    const registerFormData = {
      newUserName: values.newUserName,
      roles: values.roles,
      ssoUser: values.ssoUser,
    };

    registerUserMutate(registerFormData, {
      onError: (err) => {
        setLoading(false);
        setNotif({ error: `Error ${err.message}` });
      },
      onSuccess: () => {
        setLoading(false);
        setNotif({ success: `User ${values.newUserName} successfully created!` });
        queryClient.invalidateQueries({ queryKey: ["user"] });
      },
    });
  };

  const ssoUsers = availableUsers.map((user) => ({
    id: user.name,
    label: `${user.emails} (${user.name})`,
    object: user,
  }));

  const cordialUsers = users?.users?.map((user) => ({
    id: user.name,
    label: user.labels ? user.labels["sso-user"] : "",
    object: user,
  }));

  const associatedUsers: { id: string; label: string }[] = [];
  const unassociatedUsers: { id: string; label: string }[] = [];

  if (cordialUsers) {
    const cordialUserLabels = new Set(cordialUsers.map((user) => user.label));
    ssoUsers.forEach((ssoUser) => {
      if (cordialUserLabels.has(ssoUser.id)) {
        associatedUsers.push(ssoUser);
      } else {
        unassociatedUsers.push(ssoUser);
      }
    });
  }

  const noUsersToAdd = unassociatedUsers.length === 0;

  return (
    <>
      {noUsersToAdd && (
        <div
          style={{
            border: "1px dashed #ffcc00",
            backgroundColor: "#ffcc001a",
            borderRadius: "5px",
            padding: "1rem",
            margin: "1rem 0",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Warning />
          <span>
            All users found in {cachedOrganisation || "the organisation"} are already registered
          </span>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validate={validate}
        validateOnMount
        onSubmit={(values) => {
          !noUsersToAdd ? handleSubmit(values, modalSetters) : null;
        }}
      >
        {(formikProps) => (
          <Form style={{ minWidth: 500 }}>
            <MultiColumnRow>
              {!showInputs && (
                <ComboBox
                  style={{ width: "480px" }}
                  items={unassociatedUsers}
                  itemToString={(item) => (item ? item.label : "")}
                  onChange={({ selectedItem }) => handleComboChange(selectedItem, formikProps)}
                  placeholder="Select a user"
                  titleText="Available SSO Users"
                  id="selectedUser"
                  disabled={noUsersToAdd}
                />
              )}
            </MultiColumnRow>
            {showInputs && (
              <MultiColumnRow>
                <TextInput
                  name="ssoUser"
                  type="text"
                  labelText="Type the SSO Identifier for the user you want to register:"
                  placeholder="SSO Identifier"
                  value={SSOIdentification}
                  onChange={handleUserSSOInputChange}
                  disabled={noUsersToAdd}
                />
              </MultiColumnRow>
            )}
            <MultiColumnRow>
              <TextInput
                name="newUserName"
                type="text"
                style={{ width: "480px" }}
                labelText="Type an identifier for the user you want to register:"
                placeholder="username"
                value={userIdentification}
                onChange={handleUsernameInputChange}
                disabled={noUsersToAdd}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "right",
                  justifyContent: "space-between",
                  marginLeft: "5rem",
                }}
              />
            </MultiColumnRow>
            <CheckboxGroup roles={roles} disabled={noUsersToAdd} />
            <ModalSubmitButton />
          </Form>
        )}
      </Formik>
    </>
  );
}

const CheckboxGroup: React.FC<CheckboxGroupProps & { disabled?: boolean }> = ({
  roles,
  disabled = false,
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const handleCheckboxChange = (groupId: string) => {
    if (values.roles.includes(groupId)) {
      setFieldValue(
        "roles",
        values.roles.filter((id) => id !== groupId),
      );
    } else {
      setFieldValue("roles", [...values.roles, groupId]);
    }
  };

  return (
    <div>
      Select initial roles
      <hr />
      {roles.map((item) => (
        <Checkbox
          key={item.name}
          labelText={ParseName(item.name || "NA", "Role").resourceId || "NA"}
          id={item.name || "NA"}
          checked={values.roles.includes(item.name!)}
          onChange={() => handleCheckboxChange(item.name!)}
          name="selectedGroups"
          disabled={disabled}
        />
      ))}
    </div>
  );
};
