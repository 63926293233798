import { Pagination } from "@carbon/react";
import React from "react";

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_PAGE_SIZES = [5, 15, 25, 50, 100];

interface CustomPaginationProps {
  totalItems: number;
  pageSize?: number;
  pageSizes?: number[];
  page: number;
  onPageChange: (event: { page: number; pageSize: number }) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  totalItems,
  pageSize = DEFAULT_PAGE_SIZE,
  pageSizes = DEFAULT_PAGE_SIZES,
  page,
  onPageChange,
}) => (
  <Pagination
    totalItems={totalItems}
    pageSize={pageSize}
    pageSizes={pageSizes}
    pagesUnknown={totalItems === 0} // because displays NaN if totalItems is 0
    isLastPage={totalItems === 0} // because displays NaN if totalItems is 0
    pageText={() => "No pages"}
    page={page}
    onChange={onPageChange}
    backwardText="Previous"
    forwardText="Next"
    itemsPerPageText="Items per page:"
  />
);

export default CustomPagination;
