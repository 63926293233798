import { useEffect } from "react";

const useAccessToken = (session: any | null) => {
  useEffect(() => {
    const setAccessToken = async (accessToken: string): Promise<void> => {
      try {
        const response = await fetch(
          `https://auth.cordialapis.com/login/set?access_token=${accessToken}`,
          {
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to set access token");
        }
        console.log("Access token set successfully.");
      } catch (error: any) {
        console.error("Failed to set access token:", error.message);
      }
    };

    const fetchAndSetAccessToken = async (): Promise<void> => {
      if (!session) return;

      try {
        const accessToken = await session.getToken({
          template: "access-token",
        });
        if (accessToken) {
          await setAccessToken(accessToken);
        }
      } catch (error: any) {
        console.error("Failed to retrieve access token:", error.message);
      }
    };

    fetchAndSetAccessToken();
    const intervalId = setInterval(
      () => {
        fetchAndSetAccessToken();
      },
      30 * 60 * 1000,
    ); // Refresh token every 30 minutes

    return () => clearInterval(intervalId);
  }, [session]);
};

export default useAccessToken;
