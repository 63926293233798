import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { DepositPage } from "../../../lib/cordialapis/oracle/types";
import { cordialApisClient } from "../../../lib/cordialapis/api";
import { ParseName } from "../../../lib/api/name";
import useTreasury from "../../../features/treasuryManagement/getTreasuries";

async function fetchDepositsPage(
  pageSize: number = 25,
  pageToken?: string,
  filter?: string,
  treasuryId?: string,
): Promise<DepositPage> {
  return cordialApisClient.fetchDepositsPage(
    {
      page_size: pageSize,
      page_token: pageToken,
      filter,
    },
    treasuryId,
  );
}

interface UseDepositsPageOptions {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export const useDepositsPage = ({
  pageSize = 25,
  pageToken,
  filter,
}: UseDepositsPageOptions = {}) => {
  const { data: treasuryPage, isLoading: isLoadingTreasury, error: errorTreasury } = useTreasury();

  const queryKey = useMemo(
    () => ["depositsPage", pageSize, pageToken, filter],
    [pageSize, pageToken, filter],
  );

  const { data, error, isError, isLoading } = useQuery<DepositPage, Error>({
    queryKey,
    queryFn: () =>
      fetchDepositsPage(
        pageSize,
        pageToken,
        filter,
        treasuryPage?.treasuries?.[0]?.name
          ? ParseName(treasuryPage?.treasuries[0].name, "Treasury").resourceId
          : undefined,
      ),
    refetchOnWindowFocus: false,
    staleTime: 5000,
    refetchInterval: 10000,
    enabled: !isLoadingTreasury && !errorTreasury,
  });

  useEffect(() => {
    if (isError && error) {
      console.error("Error fetching depositsPage:", error);
    }
  }, [isError, error]);

  return {
    data: data || null,
    error: isError ? error : errorTreasury ?? null,
    isLoading: isLoading || isLoadingTreasury,
  };
};
