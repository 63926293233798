import React, { useState } from "react";
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";
import { RolePage } from "../../../lib/api/types";
import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";

interface RoleTableProps {
  rolePage: RolePage;
}

const RoleTable: React.FC<RoleTableProps> = ({ rolePage }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { page, pageSize, pageSizes, totalItems, setTotalItems, handlePageChange } =
    usePagination();

  const headers = [{ key: "name", header: "Name" }];

  const filteredRows = React.useMemo(() => {
    return rolePage.roles
      ? rolePage.roles
          .map((role, index) => ({
            id: index.toString(),
            name: role.name,
            state: role.state,
          }))
          .filter((row) => {
            const searchableText = `${row.name} ${row.state}`;
            return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
          })
      : [];
  }, [rolePage.roles, searchQuery]);

  React.useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: any) => {
    switch (cell.info.header) {
      case "name":
        return <div>{ParseName([cell.value], "Role").resourceId || "NA"}</div>;
      default:
        return <div>{cell.value}</div>;
    }
  };

  return (
    <>
      <TableToolbar>
        <TableToolbarSearch
          value={searchQuery}
          // @ts-expect-error error
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          expanded
          placeholder="Search"
        />
      </TableToolbar>
      <DataTable
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                // @ts-expect-error error
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default RoleTable;
