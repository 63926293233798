import { Field, Formik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Form, ModalSubmitButton, MultiColumnRow, TextInput } from "../../../components/form";
import { useModalSetters } from "../../../components/CModal";
import { ComboBox } from "@carbon/react";
import { TransferFormData, useTransferMutation } from "../api/createTransfer";

import { ParseName } from "../../../lib/api/name";
import { useAccountPage } from "../../accountsManagement/api/getAccounts";
import { useAddressesPage } from "../../accountsManagement/api/getAddresses";
import { useGetAssetPage } from "../../assetManagement/api/getAssetPage";

import { AssetPage } from "@/lib/api/types";
import { useChainPage } from "../../../hooks/useChain";
import { AddressListItem } from "../../../features/accountsManagement/components/AddressTable";

const validate = (setSearchParams: any) => (values: any) => {
  const errors: any = {};
  const searchParams: any = {};
  if (!values.asset) {
    errors.asset = "Required";
  } else {
    searchParams.asset = values.asset;
  }

  if (!values.amount || values.amount < 0) {
    errors.amount = "Required";
  } else {
    searchParams.amount = values.amount;
  }

  if (!values.from) {
    errors.from = "Required";
  } else {
    searchParams.from_account = values.from;
  }

  if (!values.to) {
    errors.to = "Required";
  } else {
    searchParams.to = values.to;
  }
  if (values.note) {
    searchParams.note = values.note;
  }

  if (Object.keys(searchParams).length > 0) {
    setSearchParams(searchParams);
  }
  return errors;
};

export default function TransferCreateForm() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedAccountFrom, setSelectedAccountFrom] = useState<{
    id: string;
    label: string;
    data: any;
  }>({ id: "", label: "", data: {} });
  const [selectedAccountTo, setSelectedAccountTo] = useState<{
    id: string;
    label: string;
    data: any;
  }>({ id: "", label: "", data: {} });
  const [selectedChainFrom, setSelectedChainFrom] = useState<{
    id: string;
    label: string;
    data: any;
  }>({ id: "", label: "", data: {} });

  const { data: accountsPage } = useAccountPage();
  const { data: addressesPage } = useAddressesPage();

  console.log("addressesPage", addressesPage);

  const assetPage = (useGetAssetPage({ pageSize: 100 }).data as AssetPage) || { assets: [] };
  const { data: chainPage } = useChainPage();
  const transferMutation = useTransferMutation();

  const modalSetters = useModalSetters();

  const initialValues: TransferFormData = {
    name: searchParams.get("name") || "",
    asset: searchParams.get("asset") || "",
    amount: searchParams.get("amount") || "",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
    notes: searchParams.get("notes") || "",
    labels: searchParams.get("labels") || "",
  };

  let addressList: AddressListItem[] = [];

  const combineAccountandAddress = () => {
    addressList = []; // Reset the address list
    accountsPage?.accounts?.forEach((account) => {
      account.addresses?.forEach((accountAddress) => {
        const matchingAddress = addressesPage?.addresses?.find(
          (addr) => addr.name === accountAddress,
        );
        if (matchingAddress && !addressList.some((addr) => addr.name === matchingAddress.name)) {
          addressList.push({
            ...matchingAddress,
            account: account.name,
          });
        }
      });
    });

    addressesPage?.addresses?.forEach((addr) => {
      if (!addressList.some((existingAddr) => existingAddr.name === addr.name)) {
        addressList.push({
          ...addr,
          account: "NA",
        });
      }
    });
  };

  const extractDescription = (notes: any): string => {
    console.log("notes", notes);
    if (!notes) return "";

    if (typeof notes === "object" && notes.description) {
      return `  |  ${notes.description}`;
    }

    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        console.log("parsed data", data);
        return data.description || "";
      } catch (error) {
        console.error("Invalid JSON string provided:", error);
        return notes;
      }
    }

    return "";
  };

  combineAccountandAddress();

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(setSearchParams)}
      validateOnMount={true}
      onSubmit={async (values) => {
        const { setLoading, setNotif } = modalSetters;

        setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isErrored = false;
        let errMsg = "";

        transferMutation.mutate(values, {
          onError: (err) => {
            errMsg = err.message;
            isErrored = true;
            setLoading(false);
            setNotif({ success: "", error: `Error ${errMsg}` });
          },
          onSuccess: () => {
            setLoading(false);
            setNotif({ success: `Transfer created ${values.name} successfully!`, error: "" });
          },
        });
      }}
    >
      {({ setFieldValue }) => (
        <>
          <MultiColumnRow>
            <Field
              className="cds--form-item"
              id="accountFrom"
              name="accountFrom"
              component={ComboBox}
              titleText="Account From Filter:"
              items={
                accountsPage?.accounts!.map((address) => ({
                  id: address.name,
                  label: ParseName([address.name!], "Account").resourceId,
                  data: address,
                })) || []
              }
              onChange={(event: any) => {
                setSelectedAccountFrom(event.selectedItem || { id: "", label: "", data: {} });
              }}
            />
            <Field
              className="cds--form-item"
              id="chainFrom"
              name="chainFrom"
              component={ComboBox}
              titleText="Chain filter:"
              items={
                //@ts-expect-error error
                chainPage?.chains
                  //@ts-expect-error error
                  ?.filter((chain) => chain.name)
                  //@ts-expect-error error
                  ?.map((chain) => ({
                    id: chain.name,
                    label: ParseName([chain.name!], "Chain").resourceId,
                    data: chain,
                  })) || []
              }
              onChange={(event: any) => {
                setSelectedChainFrom(event.selectedItem || { id: "", label: "", data: {} });
              }}
            />
            <Field
              className="cds--form-item"
              id="accountTo"
              name="accountTo"
              component={ComboBox}
              titleText="Account To Filter:"
              items={
                accountsPage?.accounts!.map((address) => ({
                  id: address.name,
                  label: ParseName([address.name!], "Account").resourceId,
                  data: address,
                })) || []
              }
              onChange={(event: any) => {
                setSelectedAccountTo(event.selectedItem || { id: "", label: "", data: {} });
              }}
            />
          </MultiColumnRow>
          <Form style={{ minHeight: 500 }}>
            {/* <MultiColumnRow> */}
            <Field
              className="cds--form-item"
              id="from"
              name="from"
              component={ComboBox}
              titleText="From:"
              items={
                addressList
                  .map((address) => ({
                    id: address.name,
                    label:
                      ParseName([address.name!], "Address").resourceId +
                      extractDescription(address.notes),
                    data: address,
                  }))
                  .filter((address) => {
                    if (!selectedAccountFrom.id) {
                      if (!selectedChainFrom.id) {
                        return true;
                      }
                      if (selectedChainFrom.id) {
                        const isChainMatch =
                          address.id && address.id.includes(selectedChainFrom.id);
                        return isChainMatch;
                      }
                    }

                    if (selectedAccountFrom.data && "addresses" in selectedAccountFrom.data) {
                      const accAddresses = selectedAccountFrom.data.addresses as string[];

                      const isAdressMatch = accAddresses.some((element) => element === address.id);

                      if (!selectedChainFrom.id) {
                        return isAdressMatch;
                      }
                      if (selectedChainFrom.id) {
                        const isChainMatch =
                          address.id && address.id.includes(selectedChainFrom.id);
                        return isChainMatch && isAdressMatch;
                      }
                    }
                    return false;
                  }) || []
              }
              onChange={(event: any) => {
                const address = event.selectedItem?.id;
                setFieldValue("from", address || "");
              }}
            />
            {/* </MultiColumnRow> */}

            {/* <MultiColumnRow> */}
            <Field
              className="cds--form-item"
              id="to"
              name="to"
              component={ComboBox}
              titleText="To:"
              items={
                addressList
                  .map((address) => ({
                    id: address.name,
                    label:
                      ParseName([address.name!], "Address").resourceId +
                      extractDescription(address.notes),

                    data: address,
                  }))
                  .filter((address) => {
                    if (!selectedAccountTo.id) {
                      if (!selectedChainFrom.id) {
                        return true;
                      }
                      if (selectedChainFrom.id) {
                        const isChainMatch =
                          address.id && address.id.includes(selectedChainFrom.id);
                        return isChainMatch;
                      }
                    }

                    if (selectedAccountTo.data && "addresses" in selectedAccountTo.data) {
                      const accAddresses = selectedAccountTo.data.addresses as string[];

                      const isAdressMatch = accAddresses.some((element) => element === address.id);

                      if (!selectedChainFrom.id) {
                        return isAdressMatch;
                      }
                      if (selectedChainFrom.id) {
                        const isChainMatch =
                          address.id && address.id.includes(selectedChainFrom.id);
                        return isChainMatch && isAdressMatch;
                      }
                    }
                    return false;
                  }) || []
              }
              onChange={(event: any) => {
                const address = event.selectedItem?.id;
                setFieldValue("to", address || "");
              }}
            />
            {/* </MultiColumnRow> */}

            <MultiColumnRow>
              <Field
                className="cds--form-item"
                id="asset"
                name="asset"
                component={ComboBox}
                titleText="Asset:"
                items={
                  assetPage.assets!.map((asset) => ({
                    id: asset.name,
                    label: ParseName([asset.name!], "Asset").resourceId,
                  })) || []
                }
                onChange={(event: any) => {
                  const asset = event.selectedItem?.id;
                  setFieldValue("asset", asset || "");
                }}
              />
              <Field className="cds--form-item" labelText="Amount:" name="amount" as={TextInput} />
            </MultiColumnRow>

            <ModalSubmitButton />
          </Form>
        </>
      )}
    </Formik>
  );
}
