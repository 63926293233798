import { ArrowLeft, ArrowRight, ArrowDown } from "@carbon/react/icons";
import React from "react";

interface DirectionCellRendererProps {
  direction: "incoming" | "outgoing" | "deposit";
}

export const DirectionCellRenderer: React.FC<DirectionCellRendererProps> = ({ direction }) => {
  const IconComponent =
    direction === "deposit" ? ArrowDown : direction === "incoming" ? ArrowLeft : ArrowRight;

  return (
    <IconComponent
      title={
        direction === "deposit" ? "Deposit" : direction === "incoming" ? "Incoming" : "Outgoing"
      }
      style={{ fontSize: "22px" }}
    />
  );
};
