import { Link, NavLink, Outlet } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import {
  Content,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  InlineNotification,
  HeaderMenu,
  HeaderSideNavItems,
} from "@carbon/react";
import { Moon, Sun } from "@carbon/react/icons";
import UserMenu from "../UserMenu";
import { useUser } from "@clerk/clerk-react";

interface NavItem {
  id: string;
  to: string;
  name: string;
  subItems?: NavItem[];
}

const NAV_ITEMS = [
  {
    id: "transfers",
    to: "/transfers",
    name: "Transfers",
    subItems: [
      {
        id: "transfers",
        to: "/transfers",
        name: "Ongoing Transfers",
      },
      {
        id: "deposits",
        to: "/deposits",
        name: "Historical Transfers",
      },
    ],
  },
  {
    id: "approvals",
    to: "/approvals",
    name: "Approvals",
  },
  {
    id: "policy",
    to: "/policy",
    name: "Policy",
  },
  {
    id: "accounts",
    to: "/accounts",
    name: "Accounts",
  },
  {
    id: "addresses",
    to: "/addresses",
    name: "Addresses",
  },
  {
    id: "assets",
    to: "/assets",
    name: "Assets",
  },
  {
    id: "users",
    to: "/users",
    name: "Users",
    subItems: [
      {
        id: "register-credentials",
        to: "/register-credentials",
        name: "Register Credentials",
      },
      {
        id: "users",
        to: "/users",
        name: "Users",
      },
      {
        id: "credentials",
        to: "/credentials",
        name: "Credentials",
      },
      {
        id: "roles",
        to: "/roles",
        name: "Roles",
      },
    ],
  },
] satisfies NavItem[];

function CHeaderMenuItem({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <HeaderMenuItem
      as={NavLink}
      to={to}
      onClick={(e: { currentTarget: { blur: () => void } }) => e.currentTarget.blur()}
    >
      {children}
    </HeaderMenuItem>
  );
}

function Menu() {
  return (
    <>
      {NAV_ITEMS.map((item) => {
        if (item.subItems) {
          return (
            <HeaderMenu key={item.id} menuLinkName={item.name}>
              {item.subItems.map((subItem) => (
                <CHeaderMenuItem key={subItem.id} to={subItem.to}>
                  {subItem.name}
                </CHeaderMenuItem>
              ))}
            </HeaderMenu>
          );
        }
        return (
          <CHeaderMenuItem key={item.id} to={item.to}>
            {item.name}
          </CHeaderMenuItem>
        );
      })}
    </>
  );
}

function CHeader() {
  const ariaLabel = "Cordial Treasury";
  const darkMode = useDarkMode(false, {
    classNameDark: "cds--g90",
    classNameLight: "cds--g10",
  });

  const { isSignedIn } = useUser();

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label={ariaLabel}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={Link} to="/" prefix="">
            <img
              src={darkMode.value ? "/cordial_dark.svg" : "/cordial.svg"}
              width="36"
              alt={ariaLabel}
            />
          </HeaderName>
          <HeaderNavigation aria-label={ariaLabel}>
            <Menu />
          </HeaderNavigation>

          <HeaderGlobalBar>
            {isSignedIn && (
              <div style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
                <HeaderGlobalAction aria-label="">
                  <UserMenu />
                </HeaderGlobalAction>
              </div>
            )}

            {darkMode.value ? (
              <HeaderGlobalAction
                aria-label="Light mode"
                tooltipAlignment="end"
                onClick={darkMode.disable}
              >
                <Sun size={20} />
              </HeaderGlobalAction>
            ) : (
              <HeaderGlobalAction
                aria-label="Dark mode"
                tooltipAlignment="end"
                onClick={darkMode.enable}
              >
                <Moon size={20} />
              </HeaderGlobalAction>
            )}
          </HeaderGlobalBar>
          <SideNav
            aria-label="Side navigation"
            isPersistent={false}
            expanded={isSideNavExpanded}
            onOverlayClick={onClickSideNavExpand}
            onSideNavBlur={onClickSideNavExpand}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <Menu />
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
        </Header>
      )}
    />
  );
}

export default function CLayout({ error = "" }) {
  return error ? (
    <>
      <br />
      <InlineNotification style={{ margin: "0 auto" }} hideCloseButton subtitle={error} title="" />
    </>
  ) : (
    <>
      <CHeader />
      <Content style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Outlet />
      </Content>
    </>
  );
}
