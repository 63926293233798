import { httpClientInstance } from "../../../clientInstance";
import { ParseName } from "../../../lib/api/name";
import { InviteSigner } from "../../../lib/api/signers";
import { User } from "../../../lib/api/types";

function isValidInvite(inviteCode: string): boolean {
  try {
    new InviteSigner(inviteCode);
    return true;
  } catch (e) {
    return false;
  }
}

export async function registerInviteCode(currentUser: User, inviteCode: string): Promise<string> {
  try {
    const username: string = ParseName([currentUser.name!], "User").resourceId;

    const page = await httpClientInstance.listUserCredential(username, {
      filter: `variant = "invite"`,
    });

    const possibleInvites = [
      // Invite codes may be input as literal hex,
      inviteCode,
      // Or as ascii, which we should concert to hex.
      inviteCode
        .split("")
        .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
        .join(""),
    ].filter(isValidInvite);

    let inviteName: string = "";

    for (const cred of page.credentials!) {
      if (cred && cred.variant === "invite") {
        for (const possibleMatchingInvite of possibleInvites) {
          const signer = new InviteSigner(possibleMatchingInvite);
          if (cred.public_key == signer.public_key) {
            inviteCode = possibleMatchingInvite;
            inviteName = cred.name!;
            break;
          }
        }
      }
      if (inviteName != "") {
        break;
      }
    }
    if (inviteName === "") {
      throw new Error("No invite found for your user ");
    }

    const registration = await httpClientInstance.webauthnClient.Register();

    const inviteSigner = new InviteSigner(inviteCode);

    await httpClientInstance.createCredential(
      [currentUser.name!],
      {
        variant: "web-authn",
        attestation: registration.encodedAttestion,
        // required but unused for webauthn creds
        public_key: "1234",
      },
      {
        // We need to sign this with our invite code.
        signer: inviteSigner,
      },
    );

    try {
      const webauthnUser = await httpClientInstance.getUserWebauthnCreds(username);
      console.log("webauthn user: ", webauthnUser);
      httpClientInstance.webauthnClient.setUser(webauthnUser);
    } catch (innerError) {
      console.error("Error in retrieving or setting user WebAuthn credentials: ", innerError);
    }
  } catch (error) {
    console.error("Error in postCredential function: ", error);
    throw error; // Re-throwing the error for upstream handling
  }

  return "ok";
}
