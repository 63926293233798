import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  DataTableCell,
} from "@carbon/react";

import { formatDate } from "../../../utils/formater";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import { DirectionCellRenderer } from "../../../components/itemRenderers/DirectionCellRenderer";
import TruncateTextPropWithToolTip from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import TruncateText from "../../../components/itemRenderers/TruncateText";
import { useDepositsPage } from "../api/getDepositsPage";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination, {
  DEFAULT_PAGE_SIZE,
} from "../../../components/pagination/CustomPagination";
import { StatusRenderer } from "../../../components/itemRenderers/StatusRenderer";
import { Deposit } from "../../../lib/cordialapis/oracle/types";
import { useGetAssetPage } from "../../../features/assetManagement/api/getAssetPage";
import { convertBalance } from "../api/convertBalance";

interface StateInfo {
  state: "succeeded";
  error?: Deposit["error"];
}

interface ProcessedRow extends Deposit {
  /// we add some more fields to help renderer
  id: string;
  stateInfo: StateInfo;
  create_time: string;
  /// deposit does not have a chain field, we pull it from "from"
  chainId: string;
  parsedAmount: string;
  originalName: string;
}

const DepositTable: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage, //
  } = usePagination({
    initialPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: depositsPage, isLoading, error } = useDepositsPage();
  const { data: assetsPage } = useGetAssetPage({ pageSize: 100 });

  useEffect(() => {
    if (error) {
      console.error("Error fetching deposits:", error);
    }
  }, [error]);

  const headers = [
    { key: "stateInfo", header: "State" },
    { key: "name", header: "Id" },
    { key: "create_time", header: "Created" },
    { key: "chainId", header: "Chain" },
    { key: "asset", header: "Asset" },
    { key: "direction", header: "Direction" },
    { key: "parsedAmount", header: "Amount" },
    { key: "from_ids", header: "From" },
    { key: "to_id", header: "To" },
    { key: "originalName", header: "hidden" },
  ];

  const processedRows: ProcessedRow[] =
    depositsPage?.deposits?.map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time || "") || "NA",
      asset: item.asset_id,
      parsedAmount:
        item.amount ??
        (item.balance
          ? convertBalance(
              Number(item.balance),
              assetsPage?.assets?.find((a) => a.contract === item.asset_id)?.decimals,
            )
          : "NA"),
      name: item.name?.split("/").at(-1) || "NA", //ParseName([item.name || ""], "Transaction").resourceId || "NA",
      stateInfo: {
        state: "succeeded",
        error: item.error,
      },
      chainId: item.block.chain_id || "NA",
      originalName: item.name!,
    })) || [];

  const filteredRows = processedRows.filter((row) => {
    if (!searchQuery.trim()) return true;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(lowerCaseQuery),
    );
  });

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (sortDirection === "ASC") {
      return new Date(a.create_time).getTime() - new Date(b.create_time).getTime();
    } else {
      return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
    }
  });

  const paginatedRows = sortedRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: DataTableCell<any>): JSX.Element => {
    switch (cell.info.header) {
      case "stateInfo": {
        const stateInfo = cell.value as StateInfo;
        return <StatusRenderer state={stateInfo.state} />;
      }
      case "from_ids":
        return (
          <TruncateTextPropWithToolTip
            text={cell.value[0]}
            maxLength={30}
            isBlockChainExplorerEnabled={false}
            valuesAmount={cell.value.length}
            noParse
          />
        );
      case "to_id":
        return (
          <TruncateTextPropWithToolTip
            text={cell.value}
            maxLength={30}
            isBlockChainExplorerEnabled={false}
            noParse
          />
        );
      case "asset":
        return <TruncateText text={cell.value} maxLength={30} />;
      case "direction":
        return <DirectionCellRenderer direction="deposit" />;
      case "chainId":
        return <IconCellRenderer value={cell.value} />;
      case "originalName":
        return <></>;
      default:
        return <span>{cell.value}</span>;
    }
  };

  const handleSort = (columnName: string) => {
    if (columnName === "create_time") {
      const newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
      setSortDirection(newSortDirection);
      setPage(1);
    }
  };

  const handleRowClick = (row: any) => {
    console.log("row clicked", row);
    const id = row.cells.find((cell: any) => cell.info.header === "originalName")?.value;
    if (id) {
      navigate(`/deposits/${id}`);
    }
  };

  if (isLoading) {
    return <div>Loading data, please wait...</div>;
  }

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(event.target.value);
              setPage(1);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={paginatedRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    if (header.key === "originalName") return null;

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableHeader
                        {...getHeaderProps({
                          header,
                          isSortable: header.key === "create_time",
                          sortDirection: header.key === "create_time" ? sortDirection : undefined,
                        })}
                        onClick={() => handleSort(header.key)}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  if (row.id === "originalName") return null;

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <TableRow
                      {...getRowProps({ row })}
                      onClick={() => handleRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      {row.cells
                        .filter((c) => c.info.header !== "originalName")
                        .map((cell) => (
                          <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>

      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default DepositTable;
