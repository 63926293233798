import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";

import { formatDate } from "../../../utils/formater";
import { useTransferTransactions } from "../../../features/transactionManagment/api/getTransferTransactions";
import { ParseName } from "../../../lib/api/name";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination from "../../../components/pagination/CustomPagination";

const TransferDetailsTable: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchQuery, setSearchQuery] = useState("");

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage,
    pageToken,
    setPageToken,
    setPrevPageTokens,
  } = usePagination();

  const {
    data: transactionPage,
    isLoading: isLoadingTransactions,
    error: errorTransactions,
  } = useTransferTransactions({
    name: `transfers/${id}`,
    pageSize,
    pageToken,
  });

  useEffect(() => {
    if (transactionPage?.next_page_token) {
      setPageToken(transactionPage.next_page_token);
    }
  }, [transactionPage, setPageToken]);

  useEffect(() => {
    if (transactionPage && transactionPage.total_size !== undefined) {
      setTotalItems(transactionPage.total_size);
    }
  }, [transactionPage, setTotalItems]);

  const headers = useMemo(
    () => [
      { key: "create_time", header: "Created" },
      { key: "update_time", header: "Updated" },
      { key: "state", header: "State" },
      { key: "chain", header: "Chain" },
      { key: "hash", header: "Hash" },
      { key: "fees", header: "Fees" },
      { key: "confirmations", header: "Confirmations" },
    ],
    [],
  );

  const rows = useMemo(
    () =>
      (transactionPage?.transactions || []).map((item, index) => ({
        id: `row-${index}`,
        ...item,
        create_time: formatDate(item.create_time ?? "") || "",
        update_time: formatDate(item.update_time ?? "") || "",
        chain: ParseName(item.chain, "Chain").resourceId,
        fees: item.fees?.map((fee) => `${fee.amount} `)?.join(", ") || "",
      })),
    [transactionPage],
  );

  const filteredRows = useMemo(() => {
    if (!searchQuery) return rows;
    return rows.filter((row) =>
      Object.values(row).some(
        (value) => value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) || false,
      ),
    );
  }, [rows, searchQuery]);

  if (isLoadingTransactions) {
    return <div>Loading...</div>;
  }

  if (errorTransactions) {
    return <div>Error: {errorTransactions.message}</div>;
  }

  return (
    <div>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const query = event.target.value;
              setSearchQuery(query);
              setPage(1);
              setPageToken(undefined);
              setPrevPageTokens([]);
            }}
            expanded
            placeholder="Search"
            aria-label="Search transactions"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // @ts-expect-error error
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TransferDetailsTable;
