import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  DataTableCell,
} from "@carbon/react";

import { ParseName } from "../../../lib/api/name";
import { Transfer } from "@/lib/api/types";
import { formatDate } from "../../../utils/formater";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import { DirectionCellRenderer } from "../../../components/itemRenderers/DirectionCellRenderer";
import TruncateTextPropWithToolTip from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import TruncateText from "../../../components/itemRenderers/TruncateText";
import { useTransfersPage } from "../api/getTransfersPage";
import usePagination from "../../../components/pagination/hooks/usePagination";
import CustomPagination, {
  DEFAULT_PAGE_SIZE,
} from "../../../components/pagination/CustomPagination";
import { StatusRenderer } from "../../../components/itemRenderers/StatusRenderer";

interface StateInfo {
  state: Transfer["state"];
  error?: Transfer["error"];
}

interface ProcessedRow extends Transfer {
  /// we add some more fields to help renderer
  id: string;
  stateInfo: StateInfo;
  create_time: string;
  /// transfer does not have a chain field, we pull it from "from"
  chainId: string;
}

const TransferTable: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage, //
  } = usePagination({
    initialPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: transferPage, isLoading, error } = useTransfersPage({});

  console.log("transferPage", transferPage);

  useEffect(() => {
    if (error) {
      console.error("Error fetching transfers:", error);
    }
  }, [error]);

  const headers = [
    { key: "stateInfo", header: "State" },
    { key: "name", header: "Id" },
    { key: "create_time", header: "Created" },
    { key: "chainId", header: "Chain" },
    { key: "asset", header: "Asset" },
    { key: "direction", header: "Direction" },
    { key: "amount", header: "Amount" },
    { key: "from", header: "From" },
    { key: "to", header: "To" },
  ];

  const processedRows: ProcessedRow[] =
    transferPage?.transfers?.map((item: Transfer, index: number) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time || "") || "NA",
      asset: item.symbol
        ? ParseName([item.symbol], "Symbol").resourceId
        : ParseName([item.asset], "Asset").resourceId || "NA",
      name: ParseName([item.name || ""], "Transfer").resourceId || "NA",
      stateInfo: {
        state: item.state,
        error: item.error,
      },
      chainId: ParseName([item.from], "Address").parentId || "NA",
    })) || [];

  const filteredRows = processedRows.filter((row) => {
    if (!searchQuery.trim()) return true;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(lowerCaseQuery),
    );
  });

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (sortDirection === "ASC") {
      return new Date(a.create_time).getTime() - new Date(b.create_time).getTime();
    } else {
      return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
    }
  });

  const paginatedRows = sortedRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: DataTableCell<any>): JSX.Element => {
    switch (cell.info.header) {
      case "stateInfo": {
        const stateInfo = cell.value as StateInfo;
        return <StatusRenderer state={stateInfo.state} error={stateInfo.error} />;
      }
      case "from":
      case "to":
        return (
          <TruncateTextPropWithToolTip
            text={cell.value.toString()}
            maxLength={30}
            isBlockChainExplorerEnabled={false}
          />
        );
      case "asset":
        return <TruncateText text={cell.value} maxLength={30} />;
      case "direction":
        return <DirectionCellRenderer direction={cell.value} />;
      case "chainId":
        return <IconCellRenderer value={cell.value} />;
      default:
        return <span>{cell.value}</span>;
    }
  };

  const handleSort = (columnName: string) => {
    if (columnName === "create_time") {
      const newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
      setSortDirection(newSortDirection);
      setPage(1);
    }
  };

  const handleRowClick = (row: any) => {
    const id = row.cells.find((cell: any) => cell.info.header === "name")?.value;
    if (id) {
      navigate(`/transfers/${id}`);
    }
  };

  if (isLoading) {
    return <div>Loading data, please wait...</div>;
  }

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(event.target.value);
              setPage(1);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={paginatedRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // eslint-disable-next-line react/jsx-key
                    <TableHeader
                      {...getHeaderProps({
                        header,
                        isSortable: header.key === "create_time",
                        sortDirection: header.key === "create_time" ? sortDirection : undefined,
                      })}
                      onClick={() => handleSort(header.key)}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableRow
                    {...getRowProps({ row })}
                    onClick={() => handleRowClick(row)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>

      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default TransferTable;
