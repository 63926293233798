import React, { useState, useEffect } from "react";
import { CredentialPage } from "@/lib/api/types";
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";
import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";

interface CredentialTableProps {
  credentialPage: CredentialPage;
}

const CredentialTable: React.FC<CredentialTableProps> = ({ credentialPage }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { page, pageSize, pageSizes, totalItems, setTotalItems, handlePageChange } =
    usePagination();

  const headers = [
    {
      key: "id",
      header: "Id",
    },
    {
      key: "name",
      header: "Username",
    },
    {
      key: "variant",
      header: "Variant",
    },
  ];

  const filteredRows = credentialPage.credentials
    ? credentialPage.credentials
        .map((credential, index) => ({
          id: index.toString(),
          name: credential.name,
          variant: credential.variant,
          state: credential.state,
        }))
        .filter((row) => {
          const searchableText = `${row.name} ${row.variant} ${row.state}`;
          return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
        })
    : [];

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows.length, setTotalItems]);

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: any) => {
    switch (cell.info.header) {
      case "id":
        return ParseName([cell.value], "Credential").parentId || "NA";
      case "name":
        return ParseName([cell.value], "Credential").parentId || "NA";
      default:
        return cell.value;
    }
  };

  return (
    <>
      <TableToolbar>
        <TableToolbarSearch
          value={searchQuery}
          // @ts-expect-error error
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          expanded
          placeholder="Search"
        />
      </TableToolbar>
      <DataTable
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                // @ts-expect-error error
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    if (cell.info.header === "id") {
                      return (
                        <TableCell key={cell.id}>
                          {ParseName(
                            [row.cells.find((c) => c.info.header === "name")?.value],
                            "Credential",
                          ).resourceId || "NA"}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>;
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />

      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default CredentialTable;
