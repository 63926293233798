import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, ModalSubmitButton, MultiColumnRow, TextInput } from "../../../../components/form";
import { useModalSetters } from "../../../../components/CModal";
import { UserPage } from "../../../../lib/api/types";
import { useUsers } from "../../api/useUsers";
import { UserDropdown } from "./UserDropdown";
import { UpdateUserFormData, useUpdateUserMutation } from "../../api/updateUser";
import { useOrganizationAndTreasuries } from "../../../../features/organizationManagement/api/getOrganizationsAndTreasuries";
import { useTreasuryUsers } from "../../../../features/organizationManagement/api/getTreasuryUsers";
import useCachedState from "../../../../hooks/useCachedState";
import { ComboBox, Toggle } from "@carbon/react";
import { TreasuryUser } from "../../../../features/organizationManagement/types/treasuryUserType";
import { useSession } from "@clerk/clerk-react";
import useAccessToken from "../../../../hooks/useAccessToken";
import { KEY_API } from "lib/constants";
import useTreasury from "../../../../features/treasuryManagement/getTreasuries";
import { ParseName } from "../../../../lib/api/name";

export default function AssociateClerkUsertoCordialForm() {
  const { data: organisationData } = useOrganizationAndTreasuries();
  const { data: treasuryUsersData } = useTreasuryUsers();
  const { data: treasuryPage } = useTreasury();
  const [availableUsers, setAvailableUsers] = useState<TreasuryUser[]>([]);
  const [cachedApiURL] = useCachedState<string>(KEY_API, "");
  //may fail on pagination
  const users: UserPage = useUsers(1, 500, "") as UserPage;
  const modalSetters = useModalSetters();
  const { mutate } = useUpdateUserMutation();
  const [showInputs, setShowInputs] = useState(false);
  const handleToggleChange = () => setShowInputs(!showInputs);

  const { session } = useSession();
  useAccessToken(session);

  const initialValues = {
    selectedUser: "",
    user: {},
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const treasuryId = treasuryPage?.treasuries?.[0]?.name
      ? ParseName(treasuryPage?.treasuries[0].name, "Treasury").resourceId
      : null;

    if (cachedApiURL && organisationData && treasuryUsersData && treasuryId) {
      let orgName: string | undefined;

      organisationData.userTreasuries.forEach((treasuries) => {
        if (ParseName(treasuries.name, "Treasury").resourceId === treasuryId) {
          orgName = treasuries.organization;
        }
      });

      if (orgName) {
        const clerkUsers: TreasuryUser[] = [];
        organisationData.userOrganisations.forEach((org) => {
          if (org.name === orgName) {
            Object.keys(org.users).forEach((userId) => {
              const treasuryUser = treasuryUsersData.users.find((user) => user.name === userId);

              if (treasuryUser) clerkUsers.push(treasuryUser);
            });
          }
        });

        setAvailableUsers(clerkUsers);
      }
    }
  });

  const handleSubmit = (values: any, modalSetters: any) => {
    const { setLoading, setNotif } = modalSetters;

    const formData: UpdateUserFormData = {
      user: values.user,
      clerkUser: values.clerkUser,
    };

    setLoading(true);
    const notif = { success: "User email associated" };

    mutate(formData, {
      onError: (err: any) => {
        setLoading(false);
        setNotif({ error: `Error: ${err.message}` });
      },
      onSuccess: () => {
        setLoading(false);
        setNotif(notif);
      },
    });
  };

  const comboBoxItems = availableUsers.map((user) => ({
    id: user.name,
    label: `${user.emails} (${user.name})`,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      onSubmit={(values) => handleSubmit(values, modalSetters)}
    >
      {(formikProps) => (
        <Form style={{ minWidth: 500 }}>
          <MultiColumnRow>
            <div>
              Treasury User
              <UserDropdown users={users} formikProps={formikProps} />
            </div>
          </MultiColumnRow>
          <MultiColumnRow>
            <ComboBox
              style={{ width: "500px" }}
              items={comboBoxItems}
              itemToString={(item) => (item ? item.label : "")}
              onChange={({ selectedItem }) => {
                formikProps.setFieldValue("clerkUser", selectedItem ? selectedItem.id : "");
              }}
              placeholder="Select a user"
              titleText="SSO User"
              id="selectedUser"
            />
          </MultiColumnRow>
          {showInputs && (
            <MultiColumnRow>
              <TextInput
                name="clerkUser"
                type="text"
                labelText="Type the clerk user you want to associate"
                placeholder="username@domain"
              />
            </MultiColumnRow>
          )}
          <ModalSubmitButton />
          <Toggle id="toggle-4" labelText="Manual" onToggle={handleToggleChange} />
        </Form>
      )}
    </Formik>
  );
}
