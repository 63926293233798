import CPage from "../../components/CPage";

import CredentialTable from "./components/CredentialTable";
import { useCredentialsPage } from "./api/getCredentialPage";

export default function CredentialsPage() {
  const { data: credentials } = useCredentialsPage();

  return (
    <CPage title="Credential">
      {credentials && credentials.credentials ? (
        <CredentialTable credentialPage={credentials} />
      ) : (
        <p>No Credentials</p>
      )}
    </CPage>
  );
}
