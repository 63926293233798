import { ComboBox, Loading, Modal, TextInput, Toggle } from "@carbon/react";
import { KEY_API, KEY_ORGANISATION, KEY_TREASURY, KEY_USER } from "lib/constants";
import React, { useState } from "react";
import useCachedState from "../../hooks/useCachedState";
import { useOrganizationAndTreasuries } from "./api/getOrganizationsAndTreasuries";
import { useTreasuries } from "./api/getTreasuries";
import { Organization } from "./types/organisationTypes";
import { Treasury } from "./types/treasuryTypes";

export interface Item {
  id: number;
  text: string;
  userName?: string;
  icon?: string;
}

interface OrgTreasuryEndPointSelectorProps {
  handleToggleChange: () => void;
}

const OrgTreasuryEndPointSelector: React.FC<OrgTreasuryEndPointSelectorProps> = () => {
  const { isLoading } = useTreasuries();
  const { data, error } = useOrganizationAndTreasuries();

  const [showInputs, setShowInputs] = useState(false);
  const handleToggleChange = () => setShowInputs(!showInputs);

  const [selectedOrgId, setSelectedOrgId] = useState<Organization | null>(null);
  const [userName, setUserName] = useState("");
  const [apiUrl, setAPIUrl] = useState("");
  const [invalidApiUrl, setInvalidApiUrl] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedApiURL, setCachedApiURL] = useCachedState<string>(KEY_API, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedUser, setCachedUser] = useCachedState<string>(KEY_USER, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedTreasury, setCachedTreasury] = useCachedState<string>(KEY_ORGANISATION, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cachedOrganisation, setCachedOrganisation] = useCachedState<string>(KEY_TREASURY, "");

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [selectedTreasury, setSelectedTreasury] = useState<Treasury | null>(null);

  const handleOrgChange = (org: Organization) => {
    if (org) {
      setCachedOrganisation(org.slug);
      setSelectedOrgId(org);
    } else {
      console.error("Organization is undefined");
    }
  };

  const handleTreasuryChange = (treasury: Treasury) => {
    if (treasury) {
      setSelectedTreasury(treasury);
      setAPIUrl(treasury.api);
      setCachedTreasury(treasury.description);
    } else {
      console.error("Treasury is undefined");
    }
  };

  const filteredTreasuries = data?.userTreasuries.filter(
    (treasury: Treasury) => treasury.organization === selectedOrgId?.name,
  );

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleApiURLChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAPIUrl(event.target.value);
  };

  const onConfirm = () => {
    if (showInputs) {
      try {
        new URL(apiUrl);
        setInvalidApiUrl(false);
      } catch (_) {
        return setInvalidApiUrl(true);
      }

      apiUrl && setCachedApiURL(apiUrl);
      userName && setCachedUser(userName);
    } else {
      selectedTreasury?.api && setCachedApiURL(selectedTreasury.api);
    }
    window.location.replace(`https://${window.location.host}`);
  };

  return (
    <Modal
      open={isModalOpen}
      modalHeading="Select organization and treasury"
      size="sm"
      primaryButtonText="Confirm"
      onSecondarySubmit={handleCloseModal}
      onRequestSubmit={onConfirm}
      preventCloseOnClickOutside
    >
      {!showInputs && (
        <div>
          <ComboBox
            id="organisation"
            titleText="Organization"
            placeholder="Select an organization"
            selectedItem={selectedOrgId}
            items={data?.userOrganisations || []}
            itemToString={(item) => (item ? item.display_name : "")}
            onChange={(event) => handleOrgChange(event.selectedItem as Organization)}
          />
          <ComboBox
            id="treasuries"
            titleText="Treasury"
            placeholder="Select a treasury"
            items={
              filteredTreasuries?.map((treasury) => ({
                id: treasury,
                text: treasury.description,
                treasury: treasury as Treasury,
              })) || []
            }
            itemToString={(item) => (item ? item.text : "")}
            onChange={(event) => handleTreasuryChange(event.selectedItem?.treasury as Treasury)}
          />
          {isLoading && <Loading withOverlay={true} />}
        </div>
      )}
      {showInputs && (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <TextInput
            className="cds--form-item"
            labelText="API URL"
            name="apiURL"
            id="apiURL"
            value={apiUrl}
            onChange={handleApiURLChange}
            invalid={invalidApiUrl}
            invalidText="Invalid URL"
          />
          <TextInput
            className="cds--form-item"
            labelText="User"
            name="name"
            id="userNameInput"
            value={userName}
            onChange={handleUserNameChange}
          />
          {error && <div>Invalid URL</div>}
        </div>
      )}
      <Toggle id="toggle" labelText="Manual" onToggle={handleToggleChange} />
    </Modal>
  );
};

export default OrgTreasuryEndPointSelector;
