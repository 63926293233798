import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  PopoverContent,
  Popover,
} from "@carbon/react";
import { useNotificationStore } from "../../stores/notifications";
import CPage from "../../components/CPage";
import "./DepositDetail.css";
import { formatDate } from "../../utils/formater";
import { Add } from "@carbon/react/icons";
import { useDepositDetails } from "./api/getDepositDetails";
import { useGetAssetPage } from "../assetManagement/api/getAssetPage";
import { convertBalance } from "./api/convertBalance";

const DepositDetail: React.FC = () => {
  const params = useParams<{ "*": string }>();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { data: deposit, isLoading, error } = useDepositDetails(params["*"]!);
  const { data: assetsPage } = useGetAssetPage({ pageSize: 100 });

  useEffect(() => {
    if (error) {
      addNotification({
        type: "error",
        title: "Error loading deposit details",
        message: error.message,
      });
    }
  }, [error, addNotification]);

  return (
    <CPage title="Deposit Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/deposits")}>
          Back to Deposits
        </Button>
      </div>

      <div>{isLoading ? "Loading..." : ""}</div>
      <div>{error ? "Error loading transfer details" : ""}</div>

      {!error && !isLoading && deposit && (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Deposit Name</StructuredListCell>
                    <StructuredListCell>
                      {deposit.name?.split("/").at(-1) ?? "NA"}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>State</StructuredListCell>
                    <StructuredListCell>succeeded</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Create Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(deposit?.update_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Update Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(deposit?.update_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>
                      From Address
                      {deposit.from_ids.length > 1 ? "es" : ""}
                    </StructuredListCell>
                    <StructuredListCell>
                      {deposit.from_ids.length > 1 ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {deposit.from_ids[0]}
                          <Popover open={open}>
                            <Add
                              type="button"
                              onClick={() => {
                                setOpen(!open);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                            <PopoverContent>
                              <ul
                                style={{
                                  padding: "1rem",
                                }}
                              >
                                {deposit.from_ids.map((from) => (
                                  <li key={from}>{from}</li>
                                ))}
                              </ul>
                            </PopoverContent>
                          </Popover>
                        </div>
                      ) : (
                        deposit.from_ids[0]
                      )}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>To Address</StructuredListCell>
                    <StructuredListCell>{deposit.to_id}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Asset</StructuredListCell>
                    <StructuredListCell>{deposit.asset_id}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Amount</StructuredListCell>
                    <StructuredListCell>
                      {deposit.amount ??
                        (deposit.balance
                          ? convertBalance(
                              Number(deposit.balance),
                              assetsPage?.assets?.find((a) => a.contract === deposit.asset_id)
                                ?.decimals,
                            )
                          : "NA")}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>
        </div>
      )}
    </CPage>
  );
};

export default DepositDetail;
