import { useNotificationStore } from "../../stores/notifications";
import { ParseName } from "../../lib/api/name";
import { getAddressUrl } from "../../utils/BlockchainExplorerManager";
import { Autoscaling, Copy } from "@carbon/react/icons";
import React from "react";

interface TruncateTextPropWithToolTipProps {
  text: string;
  maxLength: number;
  isBlockChainExplorerEnabled?: boolean;
  valuesAmount?: number;
  noParse?: boolean;
}

const TruncateTextPropWithToolTip: React.FC<TruncateTextPropWithToolTipProps> = ({
  text,
  maxLength,
  isBlockChainExplorerEnabled = false,
  valuesAmount = 1,
  noParse = false,
}) => {
  const isTextTruncated = text.length > maxLength;
  const { addNotification } = useNotificationStore();

  const truncateString = (str: string, num: number): string => {
    const uri = noParse ? str : ParseName([str], "Address").resourceId;

    return isTextTruncated ? uri.slice(0, num) + "..." : uri;
  };

  const handleCopyToClipboard = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    navigator.clipboard
      .writeText(noParse ? text : ParseName([text], "Address").resourceId)
      .then(() => addNotification({ type: "success", title: "Copied to clipboard", timeout: 2000 }))
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const navigateToBlockChainExplorer = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    const uri = ParseName([text], "Address").resourceId;
    const chain = ParseName([text], "Address").parentId;
    const url = getAddressUrl(chain || "", uri);

    window.open(url, "_blank", "noopener,noreferrer");
  };

  // Style object for the text span
  const textStyle: React.CSSProperties = {
    display: "inline-block",
    maxWidth: "220px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
      <span title={text} style={textStyle}>
        {valuesAmount > 1 && `(x${valuesAmount}) `}
        {truncateString(text, maxLength)}
      </span>
      {isTextTruncated && (
        <span
          onClick={handleCopyToClipboard}
          onKeyDown={handleCopyToClipboard}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          title="Click to copy"
        >
          <Copy />
        </span>
      )}
      {isBlockChainExplorerEnabled && (
        <span
          onClick={navigateToBlockChainExplorer}
          onKeyDown={navigateToBlockChainExplorer}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          title="Navigate to explorer"
        >
          <Autoscaling />
        </span>
      )}
    </div>
  );
};

export default TruncateTextPropWithToolTip;
