import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { TreasuriesData } from "../types/treasuryTypes";

async function fetchTreasuries(): Promise<TreasuriesData> {
  const response = await fetch("https://admin.cordialapis.com/v1/treasuries", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export function useTreasuries() {
  const queryKey = useMemo(() => ["treasuries"], []);
  const { data, error, isLoading, isError } = useQuery<TreasuriesData, Error>({
    queryKey,
    queryFn: fetchTreasuries,
    refetchInterval: 2000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
