import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { httpClientInstance } from "../../clientInstance";
import { TreasuryPage } from "@/lib/api/types";

async function fetchTreasury(): Promise<TreasuryPage> {
  return httpClientInstance.listTreasuries();
}

export default function useTreasury(): {
  data: TreasuryPage | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
} {
  const queryKey = useMemo(() => ["apitreasuries"], []);

  const { data, error, isLoading, isError } = useQuery<TreasuryPage, Error>({
    queryKey,
    queryFn: () => fetchTreasury(),
  });
  console.log("data from treasury", data);
  return { data, isLoading, isError, error };
}
