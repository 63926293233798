import React from "react";
import { Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import CButton from "../../../../components/CButton";
import { Form, TextInput, MultiColumnRow } from "../../../../components/form";

const validate = (setSearchParams: any) => (values: any) => {
  const errors: any = {};
  const searchParams: any = {};

  if (!values.code) {
    errors.code = "Required";
  } else {
    searchParams.code = values.code;
  }

  if (Object.keys(searchParams).length > 0) {
    setSearchParams(searchParams);
  }
  return errors;
};

export default function RegisterCredentialForm({
  registerCredential,
}: {
  registerCredential: any;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = {
    code: searchParams.get("code") || "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={validate(setSearchParams)}
      validateOnMount={true}
      onSubmit={async (values) => {
        await registerCredential.mutate(values.code);
      }}
    >
      <Form style={{ maxWidth: 400 }}>
        <MultiColumnRow>
          <TextInput name="code" type="text" labelText="" placeholder="Invite code" />
          <div>
            <CButton size="md" kind="secondary" type="submit">
              Register security key
            </CButton>
          </div>
        </MultiColumnRow>
      </Form>
    </Formik>
  );
}
